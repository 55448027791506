const Pages = {
    ProductNotCarried: '/search?keyword=productnotcarried',
    Error404: '/error/404',
    BrandsList: '/brands-list',
    BeautyInsider: '/profile/BeautyInsider',
    HappeningGenericError: '/happening/error',
    tslId: 'tX/ieWp8qUE'
};

export { Pages };
