const PAGE_TYPES = {
    NTH_CATEGORY_PAGE: 'NthCategory',
    CATEGORY_PAGE: 'Category',
    TOP_CATEGORY: 'TopCategory'
};

const REFINEMENT_STATES = {
    INACTIVE: 1,
    CHECKED: 2,
    IMPLICIT: 4
};

const REFINEMENT_TYPES = {
    COLORS: 'colors',
    RADIOS: 'radios',
    RADIOS_CUSTOM: 'radios_custom',
    CHECKBOXES: 'checkboxes',
    RANGE: 'range',
    SORT: 'sort',
    CHECKBOXES_WITH_DROPDOWN: 'checkboxesWithDropDown',
    CHECKBOXES_CUSTOM: 'checkboxes_custom',
    BEAUTY_PREFERENCES: 'beautyPreferences'
};

const REFINEMENT_NAMES = {
    PICKUP_AND_DELIVERY: 'Pickup & Delivery',
    PICKUP_AND_DELIVERY_FR: 'Ramassage et livraison'
};

const PRICE_KEYS = {
    PL: 'pl',
    PH: 'ph',
    PTYPE: 'ptype'
};

const PRICE_VALUES = {
    MANUAL: 'manual',
    MIN: 'min',
    MAX: 'max'
};

const MANUAL_PTYPE = `${PRICE_KEYS.PTYPE}=${PRICE_VALUES.MANUAL}`;

const SINGLE_SELECTS = [REFINEMENT_TYPES.RADIOS, REFINEMENT_TYPES.SORT, REFINEMENT_TYPES.RANGE];

const ENDECA_VS_CONSTRUCTOR_COLORS = {
    berry: '9010109',
    red: '100007',
    pink: '100006',
    coral: '9010111',
    brown: '100002',
    nude: '100016',
    purple: '100011',
    black: '100010',
    clear: '100012',
    incolore: '100012',
    white: '100009',
    yellow: '100003',
    green: '100015',
    blue: '100013',
    orange: '100005',
    grey: '100001',
    gold: '100014',
    silver: '100008',
    universal: '9010110',
    multi: '100004',
    unconventional: '9010128',
    blanc: '100009',
    bleu: '100013',
    'eau de parfum gold': '100014',
    multiple: '100004',
    noir: '100010',
    'non conformiste': '9010128',
    rose: '100006',
    rouge: '100007',
    universel: '9010110',
    vert: '100015',
    violet: '100011',
    argent: '100008',
    brun: '100002',
    gris: '100001'
};

const BRAND_LETTERS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '#'
];

const SEARCH_SORT_OPTIONS = [
    {
        name: 'relevancy',
        code: 'RELEVANCY',
        apiValue: '-1'
    },
    {
        name: 'bestselling',
        code: 'BEST_SELLING',
        apiValue: 'P_BEST_SELLING:1::P_RATING:1::P_PROD_NAME:0'
    },
    {
        name: 'topRated',
        code: 'TOP_RATED',
        apiValue: 'P_RATING:1'
    },
    {
        name: 'exclusive',
        code: 'EXCLUSIVE',
        apiValue: 'P_SEPH_EXCLUSIVE:1'
    },
    {
        name: 'new',
        code: 'NEW',
        apiValue: 'P_NEW:1::P_START_DATE:1'
    },
    {
        name: 'priceDesc',
        code: 'PRICE_HIGH_TO_LOW',
        apiValue: 'price:1'
    },
    {
        name: 'priceAsc',
        code: 'PRICE_LOW_TO_HIGH',
        apiValue: 'price:0'
    },
    {
        name: 'brandName',
        code: 'BRAND_NAME',
        apiValue: 'P_BRAND_NAME:0'
    }
];

const MOCK_MASTER_LIST = {
    hairCare: {
        key: 'hairCare',
        value: 'Hair Care',
        categoryId: 'cat130038',
        attributes: [
            {
                key: 'hairType',
                userKey: 'hairDescrible',
                refinementKey: 'Hair Type',
                queryParamKey: 'hairType',
                value: 'Hair Type',
                items: [
                    {
                        key: 'Medium',
                        userTraitKey: 'medium',
                        value: 'Medium',
                        image: 'https://cdn.sephora.com/flags/medium.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Fine',
                        userTraitKey: 'fine',
                        value: 'Fine',
                        image: 'https://cdn.sephora.com/flags/fine.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Thick',
                        userTraitKey: 'thick',
                        value: 'Thick',
                        image: 'https://cdn.sephora.com/flags/thick.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'hairTexture',
                userKey: 'hairTexture',
                refinementKey: 'Hair Texture',
                queryParamKey: 'hairTexture',
                value: 'Hair Texture',
                items: [
                    {
                        key: 'Straight',
                        userTraitKey: 'straight',
                        value: 'Straight',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Wavy',
                        userTraitKey: 'wavy',
                        value: 'Wavy',
                        image: 'https://cdn.sephora.com/flags/wavy.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Curly',
                        userTraitKey: 'curly',
                        value: 'Curly',
                        image: 'https://cdn.sephora.com/flags/curly.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Coily',
                        userTraitKey: 'coily',
                        value: 'Coily',
                        image: 'https://cdn.sephora.com/flags/coily.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'hairConcerns',
                userKey: 'hairConcerns',
                refinementKey: 'Concerns',
                queryParamKey: 'Concerns',
                value: 'Hair Concerns',
                items: [
                    {
                        key: 'Brassiness',
                        userTraitKey: 'brassiness',
                        value: 'Brassiness',
                        image: 'https://cdn.sephora.com/flags/brassiness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Color Fading',
                        userTraitKey: 'colorFading',
                        value: 'Color Fading',
                        image: 'https://cdn.sephora.com/flags/colorFading.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Color Safe',
                        userTraitKey: 'colorSafe',
                        value: 'Color Safe',
                        image: 'https://cdn.sephora.com/flags/colorSafe.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Curl enhancing',
                        userTraitKey: 'curlEnhancing',
                        value: 'Curl Enhancing',
                        image: 'https://cdn.sephora.com/flags/curlEnhancing.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'DamageSplit Ends',
                        userTraitKey: 'damageSplitEnds',
                        value: 'Damage / Split Ends',
                        image: 'https://cdn.sephora.com/flags/damageSplitEnds.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dandruff',
                        userTraitKey: 'dandruff',
                        value: 'Dandruff',
                        image: 'https://cdn.sephora.com/flags/dandruff.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dryness',
                        userTraitKey: 'dryness',
                        value: 'Dryness',
                        image: 'https://cdn.sephora.com/flags/dryness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'FlakyDry Scalp',
                        userTraitKey: 'flakyDryScalp',
                        value: 'Flaky / Dry Scalp',
                        image: 'https://cdn.sephora.com/flags/flakyDryScalp.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Frizz',
                        userTraitKey: 'frizzHr',
                        value: 'Frizz',
                        image: 'https://cdn.sephora.com/flags/frizzHr.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Heat Protection',
                        userTraitKey: 'heatProtection',
                        value: 'Heat Protection',
                        image: 'https://cdn.sephora.com/flags/heatProtection.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Hold Style Extending',
                        userTraitKey: 'hold_&style_extending',
                        value: 'Hold & Style Extending',
                        image: 'https://cdn.sephora.com/flags/hold_&style_extending.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Oily Scalp',
                        userTraitKey: 'oily_scalp',
                        value: 'Oily Scalp',
                        image: 'https://cdn.sephora.com/flags/oily_scalp.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Scalp Build Up',
                        userTraitKey: 'scalp_build_up',
                        value: 'Scalp Build Up',
                        image: 'https://cdn.sephora.com/flags/scalp_build_up.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Shine',
                        userTraitKey: 'shine',
                        value: 'Shine',
                        image: 'https://cdn.sephora.com/flags/shine.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'StraighteningSmoothing',
                        userTraitKey: 'straighteningSmoothing',
                        value: 'Straightening / Smoothing',
                        image: 'https://cdn.sephora.com/flags/straighteningSmoothing.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Thinning',
                        userTraitKey: 'thinning',
                        value: 'Thinning',
                        image: 'https://cdn.sephora.com/flags/thinning.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'UV Protection',
                        userTraitKey: 'uvprorection',
                        value: 'UV Protection',
                        image: 'https://cdn.sephora.com/flags/uvprorection.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Volumizing',
                        userTraitKey: 'volumizing',
                        value: 'Volumizing',
                        image: 'https://cdn.sephora.com/flags/volumizing.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'ingredientPreferences',
                userKey: 'ingredientPreferences',
                refinementKey: 'Ingredient Preferences',
                queryParamKey: 'Ingredient Preferences',
                value: 'Ingredient Preferences',
                items: [
                    {
                        key: 'Cruelty-Free',
                        userTraitKey: 'crueltyFree',
                        value: 'Cruelty Free',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Fragrance Free',
                        userTraitKey: 'fragrance_free',
                        value: 'Fragrance Free',
                        image: 'https://cdn.sephora.com/flags/wavy.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'ageRange',
                userKey: 'ageRange',
                refinementKey: 'Age Range',
                queryParamKey: 'Age Range',
                value: 'Age Range',
                items: [
                    {
                        key: '20s',
                        userTraitKey: '20s',
                        value: '20s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '30s',
                        userTraitKey: '30s',
                        value: '30s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '40s',
                        userTraitKey: '40s',
                        value: '40s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '50s',
                        userTraitKey: '50s',
                        value: '50s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    }
                ]
            }
        ]
    },
    skinCare: {
        key: 'skinCare',
        value: 'Skin Care',
        categoryId: 'cat150006',
        attributes: [
            {
                key: 'skinType',
                userKey: 'skinType',
                refinementKey: 'Skin Type',
                queryParamKey: 'Skin Type',
                value: 'Skin Type',
                items: [
                    {
                        key: 'normal',
                        userTraitKey: 'normalSk',
                        value: 'Normal',
                        image: 'https://cdn.sephora.com/flags/normal.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'oily',
                        userTraitKey: 'oilySk',
                        value: 'Oily',
                        image: 'https://cdn.sephora.com/flags/oily.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'combination',
                        userTraitKey: 'comboSk',
                        value: 'Combination',
                        image: 'https://cdn.sephora.com/flags/combination.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'dry',
                        userTraitKey: 'drySk',
                        value: 'Dry',
                        image: 'https://cdn.sephora.com/flags/dry.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'skinConcerns',
                userKey: 'skinConcerns',
                refinementKey: 'Concerns',
                queryParamKey: 'Concerns',
                value: 'Skin Concerns',
                items: [
                    {
                        key: 'AcneBlemishes',
                        userTraitKey: 'acneBlemishes',
                        value: 'Acne / Blemishes',
                        image: 'https://cdn.sephora.com/flags/acne.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Anti-aging',
                        userTraitKey: 'antiAging',
                        value: 'Anti-Aging',
                        image: 'https://cdn.sephora.com/flags/antiAging.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dark spots',
                        userTraitKey: 'darkSpots',
                        value: 'Dark Spots',
                        image: 'https://cdn.sephora.com/flags/darkSpots.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dryness',
                        userTraitKey: 'dryness',
                        value: 'Dryness',
                        image: 'https://cdn.sephora.com/flags/dryness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dullness',
                        userTraitKey: 'dullness',
                        value: 'Dullness',
                        image: 'https://cdn.sephora.com/flags/dullness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Pores',
                        userTraitKey: 'pores',
                        value: 'Pores',
                        image: 'https://cdn.sephora.com/flags/pores.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Redness',
                        userTraitKey: 'redness',
                        value: 'Redness',
                        image: 'https://cdn.sephora.com/flags/redness.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'ageRange',
                userKey: 'ageRange',
                refinementKey: 'Age Range',
                queryParamKey: 'Age Range',
                value: 'Age Range',
                items: [
                    {
                        key: '20s',
                        userTraitKey: '20s',
                        value: '20s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '30s',
                        userTraitKey: '30s',
                        value: '30s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '40s',
                        userTraitKey: '40s',
                        value: '40s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '50s',
                        userTraitKey: '50s',
                        value: '50s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    }
                ]
            }
        ]
    },
    makeup: {
        key: 'makeup',
        value: 'Makeup',
        categoryId: 'cat140006',
        attributes: [
            {
                key: 'skinType',
                userKey: 'skinType',
                refinementKey: 'Skin Type',
                queryParamKey: 'Skin Type',
                value: 'Skin Type',
                items: [
                    {
                        key: 'normal',
                        userTraitKey: 'normalSk',
                        value: 'Normal',
                        image: 'https://cdn.sephora.com/flags/normal.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'oily',
                        userTraitKey: 'oilySk',
                        value: 'Oily',
                        image: 'https://cdn.sephora.com/flags/oily.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'combination',
                        userTraitKey: 'comboSk',
                        value: 'Combination',
                        image: 'https://cdn.sephora.com/flags/combination.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'combination',
                        userTraitKey: 'comboSk',
                        value: 'Combination',
                        image: 'https://cdn.sephora.com/flags/combination.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'dry',
                        userTraitKey: 'drySk',
                        value: 'Dry',
                        image: 'https://cdn.sephora.com/flags/dry.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'skinConcerns',
                userKey: 'skinConcerns',
                refinementKey: 'Concerns',
                queryParamKey: 'Concerns',
                value: 'Skin Concerns',
                items: [
                    {
                        key: 'AcneBlemishes',
                        userTraitKey: 'acneBlemishes',
                        value: 'Acne / Blemishes',
                        image: 'https://cdn.sephora.com/flags/acne.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Anti-aging',
                        userTraitKey: 'antiAging',
                        value: 'Anti-Aging',
                        image: 'https://cdn.sephora.com/flags/antiAging.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dark spots',
                        userTraitKey: 'darkSpots',
                        value: 'Dark Spots',
                        image: 'https://cdn.sephora.com/flags/darkSpots.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dryness',
                        userTraitKey: 'dryness',
                        value: 'Dryness',
                        image: 'https://cdn.sephora.com/flags/dryness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dullness',
                        userTraitKey: 'dullness',
                        value: 'Dullness',
                        image: 'https://cdn.sephora.com/flags/dullness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Pores',
                        userTraitKey: 'pores',
                        value: 'Pores',
                        image: 'https://cdn.sephora.com/flags/pores.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Redness',
                        userTraitKey: 'redness',
                        value: 'Redness',
                        image: 'https://cdn.sephora.com/flags/redness.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'hairType',
                userKey: 'hairDescrible',
                refinementKey: 'Hair Type',
                queryParamKey: 'hairType',
                value: 'Hair Type',
                items: [
                    {
                        key: 'Medium',
                        userTraitKey: 'medium',
                        value: 'Medium',
                        image: 'https://cdn.sephora.com/flags/medium.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Fine',
                        userTraitKey: 'fine',
                        value: 'Fine',
                        image: 'https://cdn.sephora.com/flags/fine.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Thick',
                        userTraitKey: 'thick',
                        value: 'Thick',
                        image: 'https://cdn.sephora.com/flags/thick.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'ageRange',
                userKey: 'ageRange',
                refinementKey: 'Age Range',
                queryParamKey: 'Age Range',
                value: 'Age Range',
                items: [
                    {
                        key: '20s',
                        userTraitKey: '20s',
                        value: '20s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '30s',
                        userTraitKey: '30s',
                        value: '30s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '40s',
                        userTraitKey: '40s',
                        value: '40s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '50s',
                        userTraitKey: '50s',
                        value: '50s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    }
                ]
            }
        ]
    },
    fragrance: {
        key: 'fragrance',
        value: 'Fragrance',
        categoryId: 'cat160006',
        attributes: [
            {
                key: 'skinType',
                userKey: 'skinType',
                refinementKey: 'Skin Type',
                queryParamKey: 'Skin Type',
                value: 'Skin Type',
                items: [
                    {
                        key: 'normal',
                        userTraitKey: 'normalSk',
                        value: 'Normal',
                        image: 'https://cdn.sephora.com/flags/normal.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'oily',
                        userTraitKey: 'oilySk',
                        value: 'Oily',
                        image: 'https://cdn.sephora.com/flags/oily.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'combination',
                        userTraitKey: 'comboSk',
                        value: 'Combination',
                        image: 'https://cdn.sephora.com/flags/combination.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'dry',
                        userTraitKey: 'drySk',
                        value: 'Dry',
                        image: 'https://cdn.sephora.com/flags/dry.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'skinConcerns',
                userKey: 'skinConcerns',
                refinementKey: 'Concerns',
                queryParamKey: 'Concerns',
                value: 'Skin Concerns',
                items: [
                    {
                        key: 'AcneBlemishes',
                        userTraitKey: 'acneBlemishes',
                        value: 'Acne / Blemishes',
                        image: 'https://cdn.sephora.com/flags/acne.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Anti-aging',
                        userTraitKey: 'antiAging',
                        value: 'Anti-Aging',
                        image: 'https://cdn.sephora.com/flags/antiAging.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dark spots',
                        userTraitKey: 'darkSpots',
                        value: 'Dark Spots',
                        image: 'https://cdn.sephora.com/flags/darkSpots.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dryness',
                        userTraitKey: 'dryness',
                        value: 'Dryness',
                        image: 'https://cdn.sephora.com/flags/dryness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Dullness',
                        userTraitKey: 'dullness',
                        value: 'Dullness',
                        image: 'https://cdn.sephora.com/flags/dullness.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Pores',
                        userTraitKey: 'pores',
                        value: 'Pores',
                        image: 'https://cdn.sephora.com/flags/pores.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Redness',
                        userTraitKey: 'redness',
                        value: 'Redness',
                        image: 'https://cdn.sephora.com/flags/redness.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'hairType',
                userKey: 'hairDescrible',
                refinementKey: 'Hair Type',
                queryParamKey: 'hairType',
                value: 'Hair Type',
                items: [
                    {
                        key: 'Medium',
                        userTraitKey: 'medium',
                        value: 'Medium',
                        image: 'https://cdn.sephora.com/flags/medium.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Fine',
                        userTraitKey: 'fine',
                        value: 'Fine',
                        image: 'https://cdn.sephora.com/flags/fine.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Thick',
                        userTraitKey: 'thick',
                        value: 'Thick',
                        image: 'https://cdn.sephora.com/flags/thick.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'hairTexture',
                userKey: 'hairTexture',
                refinementKey: 'Hair Texture',
                queryParamKey: 'hairTexture',
                value: 'Hair Texture',
                items: [
                    {
                        key: 'Straight',
                        userTraitKey: 'straight',
                        value: 'Straight',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Wavy',
                        userTraitKey: 'wavy',
                        value: 'Wavy',
                        image: 'https://cdn.sephora.com/flags/wavy.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Curly',
                        userTraitKey: 'curly',
                        value: 'Curly',
                        image: 'https://cdn.sephora.com/flags/curly.png',
                        visableInBP: true,
                        filterable: true
                    },
                    {
                        key: 'Coily',
                        userTraitKey: 'coily',
                        value: 'Coily',
                        image: 'https://cdn.sephora.com/flags/coily.png',
                        visableInBP: true,
                        filterable: true
                    }
                ]
            },
            {
                key: 'ageRange',
                userKey: 'ageRange',
                refinementKey: 'Age Range',
                queryParamKey: 'Age Range',
                value: 'Age Range',
                items: [
                    {
                        key: '20s',
                        userTraitKey: '20s',
                        value: '20s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '30s',
                        userTraitKey: '30s',
                        value: '30s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '40s',
                        userTraitKey: '40s',
                        value: '40s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    },
                    {
                        key: '50s',
                        userTraitKey: '50s',
                        value: '50s',
                        image: 'https://cdn.sephora.com/flags/straight.png',
                        visableInBP: false,
                        filterable: true
                    }
                ]
            }
        ]
    }
};
const EXTRACT_KEY_FROM_FILTER_REGEX = /filters\[(?<key>.*)\]/;

const BEAUTY_PREFERENCES_FILTER_LIMIT = 25;

const PAGE_ONE = 1;
// TODO: if we switch to export, server tests will break with SyntaxError: Unexpected token 'export'
// we need to configure server folder/env to accept export and export default syntax.
module.exports = {
    MANUAL_PTYPE,
    PRICE_KEYS,
    PRICE_VALUES,
    PAGE_TYPES,
    REFINEMENT_STATES,
    REFINEMENT_TYPES,
    REFINEMENT_NAMES,
    ENDECA_VS_CONSTRUCTOR_COLORS,
    SINGLE_SELECTS,
    BRAND_LETTERS,
    SEARCH_SORT_OPTIONS,
    PAGE_ONE,
    MASTER_LIST: MOCK_MASTER_LIST,
    EXTRACT_KEY_FROM_FILTER_REGEX,
    BEAUTY_PREFERENCES_FILTER_LIMIT
};
