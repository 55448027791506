const SET_INITIALIZATION = 'SET_INITIALIZATION';
const SET_P13N_DATA_FOR_PREVIEW = 'SET_P13N_DATA_FOR_PREVIEW';
const SET_P13N_DATA = 'SET_P13N_DATA';
const SET_P13N_VARIATIONS = 'SET_P13N_VARIATIONS';
const SET_ACTIVE_P13N_VARIATION = 'SET_ACTIVE_P13N_VARIATION';
const SET_SID_DATA = 'SET_SID_DATA';
const UPDATE_SID_DATA = 'UPDATE_SID_DATA';

export {
    SET_INITIALIZATION,
    SET_P13N_DATA_FOR_PREVIEW,
    SET_P13N_DATA,
    SET_P13N_VARIATIONS,
    SET_ACTIVE_P13N_VARIATION,
    SET_SID_DATA,
    UPDATE_SID_DATA
};
