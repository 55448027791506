
const resources = {
    holdAtLocation: 'Or ship to a FedEx Pickup Location near you',
    changeAlternatePickup: 'Change alternate pickup location',
    shipToFedex: 'Ship to FedEx Pickup Location',
    selectLocationNearYou: 'Select a location near you'
};

export default function getResource(label) {
    return resources[label];
}
