/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import { Button } from 'components/ui';
import ApplePayButton from 'components/ApplePayButton/ApplePayButton';
import store from 'store/Store';
import checkoutApi from 'services/api/checkout';
import Debounce from 'utils/Debounce';
import OrderUtils from 'utils/Order';
import checkoutUtils from 'utils/Checkout';
import decorators from 'utils/decorators';
import { INTERSTICE_DELAY_MS } from 'components/RwdCheckout/constants';
import KlarnaActions from 'actions/KlarnaActions';
import AfterpayActions from 'actions/AfterpayActions';
import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';
import analyticsUtils from 'analytics/utils';
import { SET_PLACE_ORDER_PRE_HOOK } from 'constants/actionTypes/order';
import basketUtils from 'utils/Basket';
import CheckoutBindings from 'analytics/bindingMethods/pages/checkout/CheckoutBindings';
import actions from 'actions/Actions';
import PazeActions from 'actions/PazeActions';

const { showGiftAddressWarningModal } = actions;

class PlaceOrderButton extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            orderQuantity: 0,
            orderTotal: '',
            placeOrderPreHook: null
        };
    }

    componentDidMount() {
        const { togglePlaceOrderDisabled } = this.props;

        store.setAndWatch('order.acceptAutoReplenishTerms', this, order =>
            this.setState({ acceptAutoReplenishTerms: order.acceptAutoReplenishTerms })
        );
        store.setAndWatch('order.isPlaceOrderDisabled', this, order => {
            this.setState({ disabled: order.isPlaceOrderDisabled });
        });
        store.setAndWatch('order.orderDetails', this, order =>
            this.setState({
                orderQuantity: order.orderDetails.items.itemCount,
                orderTotal: order.orderDetails.priceInfo.orderTotal,
                isPaypalPaymentEnabled: order.orderDetails.header.isPaypalPaymentEnabled,
                userHasSavedPayPalAccount: OrderUtils.userHasSavedPayPalAccount(order.orderDetails),
                isAddressMisMatch: order.orderDetails.header.isAddressMisMatch,
                digitalGiftMsg: order.orderDetails.digitalGiftMsg,
                maxAmountToBeAuthorized: order.orderDetails.priceInfo.maxAmountToBeAuthorized
            })
        );
        store.setAndWatch('order.paymentOptions', this, order =>
            this.setState({
                existingDefaultPayment: order.paymentOptions.defaultPayment
            })
        );
        store.setAndWatch('order.isApplePayFlow', this, null, true);
        store.setAndWatch('klarna', this, data => {
            const isKlarnaDeselected = this.state.isKlarnaSelected && !data.klarna.isSelected;

            // if Klarna has been deselected -> restore the button state
            if (isKlarnaDeselected) {
                !basketUtils.isZeroCheckout() && store.dispatch(togglePlaceOrderDisabled(true));
            }

            this.setState({
                isKlarnaSelected: data.klarna.isSelected,
                isKlarnaReady: data.klarna.isReady
            });
        });
        store.setAndWatch('afterpay', this, data => {
            const isAfterpayDeselected = this.state.isAfterpaySelected && !data.afterpay.isSelected;

            // if Afterpay has been deselected -> restore the button state
            if (isAfterpayDeselected) {
                !basketUtils.isZeroCheckout() && store.dispatch(togglePlaceOrderDisabled(true));
            }

            this.setState({
                isAfterpaySelected: data.afterpay.isSelected,
                isAfterpayReady: data.afterpay.isReady
            });
        });
        store.setAndWatch('paze', this, data => {
            const isPazeDeselected = this.state.isPazeSelected && !data.paze.isSelected;

            // if Paze has been deselected -> restore the button state
            if (isPazeDeselected) {
                !basketUtils.isZeroCheckout() && store.dispatch(togglePlaceOrderDisabled(true));
            }

            this.setState({
                isPazeSelected: data.paze.isSelected,
                isPazeReady: data.paze.isReady
            });
        });
        store.setAndWatch('user', this, data => {
            this.setState({
                defaultPaymentName: data.user.selectedAsDefaultPaymentName
            });
        });
        store.watchAction(SET_PLACE_ORDER_PRE_HOOK, data => {
            this.setState({ placeOrderPreHook: data.placeOrderPreHook });
        });

        const originalPlaceOrder = this.placeOrder;
        this.placeOrder = (e, data) => {
            if (this.state.placeOrderPreHook) {
                this.state.placeOrderPreHook(e).then(originalPlaceOrder);
            } else if (this.props.isSDUItemInBasket && !this.props.sduAcceptTerms) {
                this.triggerSDUModal();
            } else {
                originalPlaceOrder(data);
            }
        };

        this.placeOrder = Debounce.preventDoubleClick(this.placeOrder);
    }

    placeFreePaymentOrder = (event, freePaymentName) => {
        this.processPlaceOrderClickEvent(freePaymentName);
        const { getText, denialMessage } = this.props;

        const errorMessage = getText('authorizeErrorMessage', [freePaymentName]);

        const paymentActionsMapping = {
            Klarna: KlarnaActions.placeOrder,
            Afterpay: AfterpayActions.placeOrder,
            Paze: PazeActions.placeOrder
        };

        const action = paymentActionsMapping[freePaymentName] || paymentActionsMapping['Paze'];

        store
            .dispatch(
                action({
                    errorMessage,
                    denialMessage,
                    transactionAmount: basketUtils.removeCurrency(this.state.orderTotal)
                })
            )
            .then(data => data && this.placeOrder(event, data));
    };

    processPlaceOrderClickEvent = freePaymentName => {
        const paymentName = freePaymentName.toLowerCase();
        const { defaultPaymentName, existingDefaultPayment } = this.state;
        const checkoutWithExistingDefaultPayment = paymentName === existingDefaultPayment;
        const actionInfo = `checkout:payment:continue with ${paymentName}`;
        const events = [anaConsts.Event.EVENT_71];

        if (defaultPaymentName || checkoutWithExistingDefaultPayment) {
            events.push('event252=1');
        }

        const eventData = {
            eventStrings: events,
            linkName: 'D=c55',
            actionInfo: actionInfo
        };

        const mostRecentAsyncLoadEvent = analyticsUtils.getMostRecentEvent('asyncPageLoad');

        if (mostRecentAsyncLoadEvent) {
            eventData.previousPage = mostRecentAsyncLoadEvent.eventInfo.attributes.previousPageName;
        }

        processEvent.process(anaConsts.LINK_TRACKING_EVENT, { data: eventData });
    };

    triggerSDUModal = () => {
        this.props.showSDUAgreementModal({
            isOpen: true,
            isBopis: this.props.isBopis,
            canCheckoutPaze: this.props.canCheckoutPaze,
            isSDUItemInBasket: this.props.isSDUItemInBasket
        });
    };

    placeOrder = (data = {}) => {
        if (this.props.isSDUItemInBasket && typeof this.props.closeSDUModal === 'function') {
            this.props.closeSDUModal();
        }

        const isSavePayPal = OrderUtils.isPayPalSaveToAccountChecked();
        const params = {
            savePayPal: this.state.isPaypalPaymentEnabled ? isSavePayPal : false,
            originOfOrder: this.props.originOfOrder
        };

        if (this.state.defaultPaymentName) {
            params.defaultPayment = this.state.defaultPaymentName;
        }

        // If this item comes from data, it means this order was placed using Klarna
        if (data.authorization_token) {
            params.klarnaAuthorizationToken = data.authorization_token;
        }

        // If this item comes from data, it means this order was placed using Paze (placeOrder in Paze Utils)
        if (data.paze) {
            params.paze = data.paze;
        }

        // When customer journey started on affiliate links, some information is stored and it needs to be send to the purchase API
        const { linkshareSiteId, linkshareTime } = analyticsUtils.getAffiliateGatewayParams();

        if (linkshareSiteId !== '' && linkshareTime !== '') {
            params.linkshareInformation = {
                linkshareSiteId,
                linkshareTime
            };
        }

        //disable place order button just in case
        store.dispatch(this.props.togglePlaceOrderDisabled(true));

        const birthdayGift = basketUtils.getBirthdayGift();

        decorators
            .withInterstice(
                checkoutApi.placeOrder,
                INTERSTICE_DELAY_MS
            )(params)
            .then(submittedDetails => {
                if (birthdayGift) {
                    const { skuId, productId, brandName, productName } = birthdayGift.sku;
                    CheckoutBindings.birthdayGiftRedemption({ skuId, productId, brandName, productName });
                }

                if (this.props.isBopis) {
                    CheckoutBindings.placeBOPISOrder();
                } else {
                    CheckoutBindings.placeStandardOrder();
                }

                this.onPlaceOrderSuccess(submittedDetails);
            })
            .catch(this.onPlaceOrderFailure);
    };

    onPlaceOrderSuccess = submittedDetails => {
        this.props.isBopis && analyticsUtils.setNextPageData({ customData: { clickBopisPlaceOrder: true } });
        checkoutUtils.placeOrderSuccess(submittedDetails);
    };

    onPlaceOrderFailure = errorData => {
        checkoutUtils.placeOrderFailure(errorData, this);
    };

    showGiftAddressWarning = () => {
        const placeOrderCallback = this.placeOrder;
        const recipientName = this.state?.digitalGiftMsg?.recipientName;

        return store.dispatch(showGiftAddressWarningModal({ isOpen: true, placeOrderCallback, recipientName }));
    };

    renderPaymentButton = () => {
        const freePaymentName = this.getFreePaymentName();
        const {
            isApplePayFlow,
            isKlarnaSelected,
            isKlarnaReady,
            isAfterpaySelected,
            isAfterpayReady,
            isPazeSelected,
            isPazeReady,
            isAddressMisMatch
        } = this.state;
        const {
            block = true, children = this.props.placeOrder, isBopis, canCheckoutPaze, getText
        } = this.props;

        // TODO PAZE: Change isPazeSelected to isPazeSelected && isPazeReady, since we need to load the iframe
        const isFreePaymentAvailable =
            (isKlarnaSelected && isKlarnaReady) || (isAfterpaySelected && isAfterpayReady) || (isPazeSelected && isPazeReady && canCheckoutPaze);
        const isButtonEnabled = !this.state.disabled && isFreePaymentAvailable;

        if (isApplePayFlow) {
            return (
                <ApplePayButton
                    isApplePayPayment={true}
                    isBopis={isBopis}
                    block={block}
                />
            );
        } else if (isButtonEnabled) {
            return (
                <Button
                    variant='special'
                    onClick={e => this.placeFreePaymentOrder(e, freePaymentName)}
                    block={block}
                    minWidth={[null, '15em']}
                    data-at={Sephora.debug.dataAt('place_order_btn')}
                    children={getText(`place${freePaymentName}Order`)}
                />
            );
        } else {
            return (
                <Button
                    variant='special'
                    disabled={this.getDisabledState()}
                    onClick={isAddressMisMatch ? this.showGiftAddressWarning : this.placeOrder}
                    block={block}
                    minWidth={[null, '15em']}
                    data-at={Sephora.debug.dataAt('place_order_btn')}
                    children={children}
                />
            );
        }
    };

    getDisabledState = () => {
        const disabledButton = this.props.disabled;
        const {
            isKlarnaSelected, isKlarnaReady, isAfterpaySelected, isAfterpayReady, isPazeSelected, isPazeReady, disabled
        } = this.state;

        return (
            disabled ||
            disabledButton ||
            (isKlarnaSelected && !isKlarnaReady) ||
            (isAfterpaySelected && !isAfterpayReady) ||
            (isPazeSelected && !isPazeReady)
        );
    };

    getFreePaymentName = () => {
        const { isKlarnaSelected, isPazeSelected } = this.state;

        if (isKlarnaSelected) {
            return 'Klarna';
        } else if (isPazeSelected) {
            return 'Paze';
        } else {
            return 'Afterpay';
        }
    };

    render() {
        return <>{this.renderPaymentButton()}</>;
    }
}

export default wrapComponent(PlaceOrderButton, 'PlaceOrderButton', true);
