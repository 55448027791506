import ufeApi from 'services/api/ufeApi';

const deleteApptReservation = (
    token,
    {
        activityType, // (events|services)
        confirmationNumber,
        channel = 'rwd'
    }
) => {
    const url = `/gway/v2/happening/${activityType}/reservations/${confirmationNumber}?channel=${channel}`;

    return ufeApi
        .makeRequest(url, {
            method: 'DELETE',
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        .then(res => (res.errorCode ? Promise.reject(res) : res));
};

export default deleteApptReservation;
