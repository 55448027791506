import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import { Box } from 'components/ui';
import localeUtils from 'utils/LanguageLocale';
import resourceWrapper from 'utils/framework/resourceWrapper';

const { getLocaleResourceFile } = localeUtils;

function BiFreeShipping({
    hasMetFreeShippingThreshhold, isSignedInBIUser, baseColor, freeShipColor, isFreeShipBold, ...customBoxStyle
}) {
    const getText = resourceWrapper(getLocaleResourceFile('components/RwdBasket/Messages/BiFreeShipping/locales', 'BiFreeShipping'));
    let coloredText = `{color:${freeShipColor}}${getText('biFreeShipVar')}{color}`;

    if (isFreeShipBold) {
        coloredText = `*${coloredText}*`;
    }

    // INFL-2247
    return (
        <Box
            color={baseColor}
            {...customBoxStyle}
        >
            {getText(isSignedInBIUser || !hasMetFreeShippingThreshhold ? 'biFreeShip' : 'freeShipQualifies', true, coloredText)}
        </Box>
    );
}

BiFreeShipping.defaultProps = {
    isFreeShipBold: false
};

export default wrapFunctionalComponent(BiFreeShipping, 'BiFreeShipping');
