import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import userUtils from 'utils/User';
import p13nUtils from 'utils/localStorage/P13n';
import BannerListBindings from 'analytics/bindingMethods/components/bccComponents/BannerList/BannerListBindings';
import { p13nSelector } from 'selectors/p13n/p13nSelector';
import { sendCmsComponentEvent, matchContexts } from 'analytics/utils/cmsComponents';
import { personalizationSelector } from 'viewModel/selectors/personalization/personalizationSelector';
import { coreUserDataSelector } from 'viewModel/selectors/user/coreUserDataSelector';
import constants from 'constants/content';
import Empty from 'constants/empty';
import anaConsts from 'analytics/constants';

const {
    COMPONENT_TYPES: { BANNER_LIST }
} = constants;

const {
    CMS_COMPONENT_EVENTS: { IMPRESSION, ITEM_CLICK }
} = anaConsts;
const { wrapHOC } = FrameworkUtils;

const functions = (dispatch, ownProps) => ({
    triggerImpression: function () {
        const { items } = ownProps;
        const personalizationData = p13nUtils.getAllPersonalizedCache();
        const biStatus = userUtils.getBiStatus();
        const profileId = userUtils.getProfileId();
        const profileStatus = userUtils.getProfileStatus();
        const bannersPersonalizedData = [];
        const userData = {
            biStatus,
            profileId,
            profileStatus
        };

        // Check for each item in the banner list to see if its context matches any of the
        // contexts for personalization data in the local storage.
        items.forEach(item => {
            const { personalization } = item;

            if (personalization) {
                const personalizationLocalData = matchContexts(personalization, personalizationData);

                if (personalizationLocalData) {
                    bannersPersonalizedData.push(personalizationLocalData);
                }
            }
        });

        // Only trigger analytics for viewable impression if they also have personalization data.
        if (bannersPersonalizedData.length > 0) {
            userData.bannersPersonalizedData = bannersPersonalizedData;
            BannerListBindings.bannerItemsImpression(userData);
        }
    },
    triggerCMSImpression: function (targets, renderItems) {
        const { items, sid, title = '' } = ownProps;
        const currentItems = (renderItems || items)
            ?.map((item, index) => ({
                ...item,
                itemIndex: index
            }))
            .filter((item, index) => targets.includes(index));

        const eventName = IMPRESSION;

        setTimeout(() => {
            sendCmsComponentEvent({
                items: currentItems,
                eventName,
                title,
                sid,
                component: BANNER_LIST
            });
        }, 1000);
    },
    triggerClick: async function (data, position) {
        const { sid: bannerSid, variationData } = data;
        const { items, sid, title } = ownProps;
        const eventName = ITEM_CLICK;
        const _items = variationData ? [{ ...variationData, itemIndex: position }] : items;

        if (position && !variationData) {
            _items[position].itemIndex = position;
        }

        await sendCmsComponentEvent({
            items: _items,
            eventName,
            title,
            sid,
            clickedSid: bannerSid,
            component: BANNER_LIST
        });
    }
});

const connectedBannerList = connect(
    createSelector(
        coreUserDataSelector,
        p13nSelector,
        personalizationSelector,
        (_state, ownProps) => ownProps,
        (user, p13n, personalization, ownProps) => {
            const { isNBCEnabled = false, NBCSlotCounts = Empty.Object } = ownProps?.personalization || Empty.Object;
            const { isPersonalizationInitializing } = personalization;
            const personalizedComponent = (isNBCEnabled && personalization.personalizedComponent) || Empty.Object;

            const _items = [...ownProps.items];

            // Limit the number of items to display given the slots count for anonymous users if enabled for NBC
            const itemsToShow = user.isAnonymous && isNBCEnabled && NBCSlotCounts?.web ? _items.slice(0, NBCSlotCounts.web) : ownProps.items;

            return {
                p13n,
                personalizedComponent,
                isPersonalizationInitializing,
                items: itemsToShow
            };
        }
    ),
    functions
);

const withBannerListProps = wrapHOC(connectedBannerList);

export {
    functions, withBannerListProps
};
