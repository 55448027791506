const SHOW_EDIT_MY_PROFILE_MODAL = 'SHOW_EDIT_MY_PROFILE_MODAL';
const SHOW_EDIT_FLOW_MODAL = 'SHOW_EDIT_FLOW_MODAL';
const SHOW_SAVED_MESSAGE = 'SHOW_SAVED_MESSAGE';
const UPDATE_BI_ACCOUNT = 'UPDATE_BI_ACCOUNT';
const GET_ACCOUNT_HISTORY_SLICE = 'GET_ACCOUNT_HISTORY_SLICE';
const SET_ACCOUNT_HISTORY_SLICE = 'SET_ACCOUNT_HISTORY_SLICE';
const SHOW_SOCIAL_REGISTRATION_MODAL = 'SHOW_SOCIAL_REGISTRATION_MODAL';
const SHOW_SOCIAL_REOPT_MODAL = 'SHOW_SOCIAL_REOPT_MODAL';
const SET_CUSTOMER_LIMIT = 'SET_CUSTOMER_LIMIT';
const SET_CUSTOMER_LIMIT_ERROR = 'SET_CUSTOMER_LIMIT_ERROR';

export {
    SHOW_EDIT_MY_PROFILE_MODAL,
    SHOW_EDIT_FLOW_MODAL,
    SHOW_SAVED_MESSAGE,
    UPDATE_BI_ACCOUNT,
    GET_ACCOUNT_HISTORY_SLICE,
    SET_ACCOUNT_HISTORY_SLICE,
    SHOW_SOCIAL_REGISTRATION_MODAL,
    SHOW_SOCIAL_REOPT_MODAL,
    SET_CUSTOMER_LIMIT,
    SET_CUSTOMER_LIMIT_ERROR
};
