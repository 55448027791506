export default function getResource(label, vars = []) {
    const resources = {
        birthdayIconText: 'Free birthday gift',
        earnPointsIconText: 'Earn points per dollar',
        redeemTrialIconText: 'Redeem trial size products & sets',
        accessIconText: 'Access to in-store beauty services',
        applePaySignIn: 'Sign in or create a Sephora account to complete your order with Apple Pay.',
        emailAddressLabel: 'Email Address',
        emailAddressPlaceholder: 'Email Address',
        whatsYourEmailAddressLabel: 'What is your email address?',
        signInToSaveBIPointsMessage: 'Sign in to save your Beauty Insider points & redeem your free rewards.',
        signInFasterBookingMessage: 'Sign in for faster booking and to save your reservation to your account.',
        haveABIAccountMessage: 'Have a Beauty Insider account? Use the email you signed up with.',
        notYouMessage: 'Not you?',
        haveAPasswordMessage: 'Do you have a sephora.com password?',
        imNewMessage: 'No, I am new to the website',
        iHaveAPasswordMessage: 'Yes, I have a password',
        passwordLabel: 'Password',
        passwordPlaceholder: 'Password',
        enterPasswordErrorMessage: 'Please enter your password.',
        forgotPasswordLink: 'Forgot password?',
        staySignedInLabel: 'Keep me signed in',
        createAccountLabel: 'Create your Sephora account',
        applePaySephoraAccountAgreement: 'By tapping “Buy with Apple Pay,” you are registering for Sephora.com, and you agree to our',
        privacyPolicyLink: 'Privacy Policy',
        andText: 'and',
        sephoraTermsOfUse: 'Sephora Terms of Use',
        automaticallyEmailsMessage: ' You will automatically receive emails from Sephora.',
        giftCardsApplePayMessage: 'Gift cards cannot be combined with Apple Pay.',
        signIn: 'Sign In',
        buyWithLabel: 'Buy with',
        continueButtonLabel: 'Continue',
        newAccountHeading: 'New to Sephora?',
        createAccount: 'Create Account',
        existingAccountErrorMessage: 'An account already exists for the email address you’ve entered. Please sign in or choose another email address.',
        showPasswordLinkAriaLabel: 'Show password text',
        hidePasswordLinkAriaLabel: 'Hide password text',
        staySignedInTooltip: 'Selecting this option means you won’t have to sign in as often on this device. For your security, we recommend only doing this on your personal devices.',
        lead: 'Sign in or create an account to enjoy *FREE standard shipping* on all orders.',
        pointsAndFreeShip: `Sign in to save your *${vars[0]} Beauty Insider points*, get *FREE standard shipping* & redeem your free rewards.`,
        unableToLogYouIn: 'We were unable to log you in, please call Customer Service for assistance.',
        genericErrorMessage: 'Oops! Something went wrong. Please try again later.',
        signedIn: 'You’re Signed in!',
        passwordLengthInvalid: 'Please review your password. Passwords are 6 to 12 characters long.',
        disclaimerBannerText: 'Sign in required for Same-Day Delivery, Buy Online and Pickup, and Auto Replenish orders.'
    };

    return resources[label];
}
