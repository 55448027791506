import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { Box, Text } from 'components/ui';
import { screenReaderOnlyStyle } from 'style/config';
import Select from 'components/Inputs/Select/Select';
import Chevron from 'components/Chevron/Chevron';
import { lineItem } from 'utils/LineItem';
import languageLocale from 'utils/LanguageLocale';
import skuUtils from 'utils/Sku';
import { forms } from 'style/config';
import constants from 'components/Product/SkuQuantity/constants';
import skuHelpers from 'utils/skuHelpers';

const { getLocaleResourceFile } = languageLocale;
const { MIN_WIDTH } = constants;

class SkuQuantity extends BaseClass {
    state = {
        isInReplenishBasket: false
    };
    componentDidMount() {
        this.setState({
            isInReplenishBasket: skuHelpers.isInAutoReplenishmentBasket(this.props.currentSku.skuId)
        });
    }
    componentDidUpdate(prevProps) {
        if ((this.props.basket && prevProps.basket !== this.props.basket) || this.props.currentSku.skuId !== prevProps.currentSku.skuId) {
            this.setState({
                isInReplenishBasket: skuHelpers.isInAutoReplenishmentBasket(this.props.currentSku.skuId)
            });
        }
    }

    render() {
        const getText = getLocaleResourceFile('components/Product/SkuQuantity/locales', 'SkuQuantity');
        const {
            currentSku, inputProps, customStyle, showQuantityPicker, displayShowQuantityPicker, isSdd, isBopis
        } = this.props;
        const isOutOfStock = currentSku.isOutOfStock;
        const id = 'qty_' + currentSku.skuId;
        const quantities = skuUtils.purchasableQuantities(this.props.currentSku, this.props.startFromZero);
        let shippingText = getText('standardShipping');

        if (isSdd) {
            shippingText = getText('sdd');
        }

        if (isBopis) {
            shippingText = getText('bopis');
        }

        return (
            <Box>
                <label
                    htmlFor={id}
                    children={getText('qty')}
                    css={screenReaderOnlyStyle}
                />
                <Select
                    id={id}
                    {...inputProps}
                    value={isOutOfStock ? lineItem.OOS_QTY : this.props.skuQuantity}
                    aria-labelledby={id}
                    disabled={this.props.disabled}
                    onChange={e => this.props.handleSkuQuantity(e.target.value)}
                    marginBottom={null}
                    isNotSelectable={this.props.isNotSelectable}
                    isInReplenishBasket={this.state.isInReplenishBasket}
                    customStyle={{
                        input: {
                            minWidth: inputProps?.size ? MIN_WIDTH.SM : MIN_WIDTH.BASE,
                            textAlign: 'center',
                            ...customStyle,
                            ...(showQuantityPicker && displayShowQuantityPicker && styles.transparent)
                        }
                    }}
                    data-at={Sephora.debug.dataAt('sku_qty')}
                    {...(showQuantityPicker && displayShowQuantityPicker && { chevronColor: 'transparent' })}
                >
                    {quantities.concat(isOutOfStock ? [lineItem.OOS_QTY] : []).map((option, index) => (
                        <>
                            <option
                                css={{ color: forms.COLOR }}
                                key={index.toString()}
                                value={option}
                            >
                                {option}
                            </option>
                            {showQuantityPicker && displayShowQuantityPicker && this.props.skuQuantity === option && getText('inBasket')}
                        </>
                    ))}
                </Select>
                {showQuantityPicker && displayShowQuantityPicker && (
                    <Box style={styles.selectOverlay}>
                        <Box style={styles.topRow}>
                            <Text fontWeight='bold'>
                                {this.props.skuQuantity} {getText('inBasket')}
                            </Text>
                            <Chevron
                                color={'white'}
                                direction='down'
                                size={8}
                                css={styles.chevron}
                            />
                        </Box>
                        <Text children={shippingText} />
                    </Box>
                )}
            </Box>
        );
    }
}

const styles = {
    selectOverlay: {
        color: 'white',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    topRow: {
        display: 'flex',
        alignItems: 'center'
    },
    transparent: {
        color: 'transparent'
    },
    chevron: {
        position: 'relative',
        marginLeft: 8
    }
};

SkuQuantity.prototype.shouldUpdatePropsOn = ['currentSku.skuId', 'skuQuantity', 'basket'];

export default wrapComponent(SkuQuantity, 'SkuQuantity', true);
