/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import BaseClass from 'components/BaseClass';
import React from 'react';
import { wrapComponent } from 'utils/framework';
import InfoButton from 'components/InfoButton/InfoButton';
import { Box } from 'components/ui';
import personalizedPreviewPlacementsApi from 'services/api/personalizedPreviewPlacements';
import VariationCarousel from 'components/PersonalizedPreviewPlacements/VariationCarousel/VariationCarousel';
import userUtils from 'utils/User';
import localeUtils from 'utils/LanguageLocale';
import { DebouncedResize } from 'constants/events';

const getText = localeUtils.getLocaleResourceFile('components/PersonalizedPreviewPlacements/PersonalizedPlacement/locales', 'PersonalizedPlacement');

class PersonalizedPlacement extends BaseClass {
    state = {
        variations: [],
        ruleIds: [],
        dates: {},
        models: [],
        isVariationCarouselOpen: false,
        isRuleDetailBoxOpen: false
    };

    infoButtonRef = React.createRef();

    setParentWidth = () => {
        this.setState({
            parentWidth: this.infoButtonRef.current?.parentElement?.clientWidth
        });
    };

    componentDidMount() {
        if (this.props.isPersistentBanner) {
            window.addEventListener(DebouncedResize, this.setParentWidth);
            this.setParentWidth();
        }

        this.props.setSidData(this.props.sid);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sid !== this.props.sid) {
            this.props.updateSidData({
                prevSid: prevProps.sid,
                currentSid: this.props.sid
            });
        }
    }

    componentWillUnmount() {
        this.props.isPersistentBanner && window.removeEventListener(DebouncedResize, this.setParentWidth);
    }

    triggerErrorModal = message => {
        this.props.showInfoModal({
            isOpen: true,
            title: 'Error',
            message,
            showCloseButton: true
        });
    };

    getRuleSet = isAnonymous => {
        const contextId = isAnonymous ? this.props.contextId : this.props.personalizedComponent.context;

        if (!isAnonymous && !this.props.personalizedComponent?.ruleId) {
            this.triggerErrorModal(getText('noRuleFound'));

            return;
        }

        personalizedPreviewPlacementsApi
            .getRuleSetByContextId(contextId)
            .then(data => {
                if (!data?.rules) {
                    this.triggerErrorModal(getText('noRuleSets'));
                    this.setState({ isVariationCarouselOpen: false });
                } else {
                    const variations = [],
                        ruleIds = [],
                        models = [];
                    data?.rules?.forEach(item => {
                        if (isAnonymous) {
                            if (!variations.includes(item?.variation)) {
                                variations.push(item?.variation);
                                ruleIds.push(item?.ruleId);
                                models.push(item?.model);
                            }
                        } else {
                            if (item?.ruleId === this.props.personalizedComponent?.ruleId) {
                                variations.push(item?.variation);
                                ruleIds.push(item?.ruleId);
                                models.push(item?.model);
                            }
                        }
                    });
                    this.setState(
                        {
                            variations,
                            ruleIds,
                            models,
                            dates: {
                                startDate: data?.startDate,
                                endDate: data?.endDate
                            },
                            isVariationCarouselOpen: isAnonymous,
                            isPrioritizationTypeStandard: data?.prioritizationType === 'STANDARD'
                        },
                        () => {
                            this.props.setPersonalizedVariations(this.props.contextId, this.state.variations, () => {
                                this.triggerErrorModal(getText('noBannerData'));
                                this.setState({ isVariationCarouselOpen: false });
                            });
                            !isAnonymous && this.getRuleData(this.props.personalizedComponent?.ruleId);
                        }
                    );
                }
            })
            .catch(() => {
                this.triggerErrorModal(getText('noRuleSets'));
                this.setState({ isVariationCarouselOpen: false });
            });
    };

    handleInfoButtonClick = () => {
        this.getRuleSet(userUtils.isAnonymous());
    };

    getRuleData = (ruleForLoggedInUser, index = null) => {
        if (this.state.isPrioritizationTypeStandard) {
            const isAnonymous = userUtils.isAnonymous();
            let ruleId;

            if (isAnonymous) {
                if (index) {
                    ruleId = this.state.ruleIds[index - 1];
                } else {
                    ruleId = this.state.ruleIds[this.props.activePage - 1];
                }
            } else {
                ruleId = ruleForLoggedInUser;
            }

            personalizedPreviewPlacementsApi
                .getRuleDetails(ruleId)
                .then(data => {
                    if (data?.statusCode === 400) {
                        this.triggerErrorModal(getText('noRuleData'));
                        this.setState({
                            isVariationCarouselOpen: false,
                            isRuleDetailBoxOpen: false
                        });
                    } else {
                        const regex = /\{([^{}]+?)\}/g;
                        const conditions = [];
                        let match;

                        while ((match = regex.exec(data?.rule)) !== null) {
                            const condition = match[1].trim();
                            conditions.push(condition);
                        }

                        const rule = {
                            conj: data?.rootNode?.conj,
                            conditions: conditions,
                            name: data?.name,
                            isPrioritizationTypeStandard: this.state.isPrioritizationTypeStandard
                        };

                        this.setState({
                            rule,
                            isVariationCarouselOpen: true,
                            isRuleDetailBoxOpen: true
                        });
                    }
                })
                .catch(() => {
                    this.triggerErrorModal(getText('noRuleData'));
                });
        } else {
            const condition = `model = ${this.state.models[this.props.activePage - 1]}`;
            const rule = {
                name: getText('scoreBasedPriority'),
                conditions: [condition],
                isPrioritizationTypeStandard: this.state.isPrioritizationTypeStandard
            };

            this.setState({
                rule,
                isVariationCarouselOpen: true,
                isRuleDetailBoxOpen: true
            });
        }
    };

    closeVariationCarousel = () => {
        this.setState({
            isVariationCarouselOpen: false
        });
    };

    closeRuleBox = () => {
        this.setState({
            isRuleDetailBoxOpen: false
        });
    };

    setVariation = rule => {
        const variationData = {
            [this.props.contextId]: rule
        };
        const hasVariation = this.props.p13n.variations?.[this.props.contextId]?.find(item => item?.sys?.id === rule);

        if (!hasVariation && rule) {
            this.triggerErrorModal(getText('noBannerDataForVariation'));
            this.closeVariationCarousel();

            return false;
        }

        this.props.setActiveVariation(variationData);

        return true;
    };

    handleCarouselItemClick = index => {
        const variationIsSet = this.setVariation(this.state.variations[index - 1]);

        if (this.props.activePage !== index && this.state.isRuleDetailBoxOpen && variationIsSet) {
            this.getRuleData('', index);
        }
    };

    render() {
        const { isPersistentBanner } = this.props;

        const iconPlacement = this.state.parentWidth - 50;
        const persistentBannerLeft = iconPlacement > 1248 ? 1248 : iconPlacement;

        return (
            <Box
                position='relative'
                zIndex='1000'
                ref={this.infoButtonRef}
            >
                <InfoButton
                    buttonType='personalizedInfo'
                    position='absolute'
                    top={!isPersistentBanner && 12}
                    left={isPersistentBanner ? persistentBannerLeft : 12}
                    bottom={isPersistentBanner && 2}
                    size={[24, 34]}
                    onClick={this.handleInfoButtonClick}
                />
                {this.state.isVariationCarouselOpen && (
                    <VariationCarousel
                        sid={this.props.sid}
                        onClose={() => {
                            this.closeVariationCarousel();
                            this.closeRuleBox();
                        }}
                        position={this.infoButtonRef.current?.getBoundingClientRect()}
                        variations={this.state.variations}
                        activePage={this.props.activePage}
                        resetVariation={() => {
                            this.closeRuleBox();
                            this.setVariation(null);
                        }}
                        viewRuleClick={this.getRuleData}
                        onClick={index => {
                            this.handleCarouselItemClick(index);
                        }}
                        isRuleDetailBoxOpen={this.state.isRuleDetailBoxOpen}
                        rule={{ ...this.state.rule, dates: this.state.dates }}
                        OnRuleClose={this.closeRuleBox}
                    />
                )}
            </Box>
        );
    }
}

export default wrapComponent(PersonalizedPlacement, 'PersonalizedPlacement');
