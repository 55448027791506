import ufeApi from 'services/api/ufeApi';
import getAuthDataId from 'services/api/utility/getAuthDataId';

function getProductSamples(productId) {
    const authId = getAuthDataId();
    let profileId = '';

    if (authId) {
        profileId = '&profileId=' + authId;
    }

    const url = `/api/v3/catalog/products/${productId}?productSamples=true` + profileId;

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getProductSamples;
