import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';

import {
    Box, Button, Flex, Grid, Image, Text
} from 'components/ui';
import ActionButtons from 'components/Content/Happening/ActionButtons';
import HappeningImg from 'components/SharedComponents/HappeningImg';

import formValidatorUtils from 'utils/FormValidator';
import localeUtils from 'utils/LanguageLocale';
import locationUtils from 'utils/Location';
import { formatAppointmentDate, formatAppointmentTimeFrame, ensureSephoraPrefix } from 'utils/happening';
import resourceWrapper from 'utils/framework/resourceWrapper';

import { borders, colors, shadows } from 'style/config';

import { SERVICE_BUTTONS } from 'components/Content/Happening/ActionButtons/constants';

import HappeningBindings from 'analytics/bindingMethods/components/Content/Happening/HappeningBindings';

const { getLocaleResourceFile } = localeUtils;

function BookingConfirmationDetails(props) {
    const getText = getLocaleResourceFile('components/Content/Happening/BookingConfirmationDetails/locales', 'BookingConfirmationDetails');

    const {
        artist,
        confirmationNumber,
        email,
        startDateTime,
        store,
        serviceInfo = {},
        selectedFeatureToFocus,
        smsEnabled,
        smsPhoneNumber,
        specialRequests
    } = props.bookingInfo;
    const {
        duration, durationInt, displayName: activityName, type, imageUrl
    } = serviceInfo;
    const storeName = store?.displayName;
    const artistName = artist?.displayName;

    const formattedDate = formatAppointmentDate(startDateTime, store.timeZone);
    const formattedTime = formatAppointmentTimeFrame(startDateTime, store.timeZone, durationInt);

    const getCalendarInfo = () => {
        return {
            activityName,
            storeName,
            confirmationNumber,
            startDateTime,
            duration,
            type
        };
    };

    const getFormattedText = resourceWrapper(getText);

    const confirmationMethod = smsEnabled && smsPhoneNumber ? 'phoneConfirmation' : 'emailConfirmation';
    const confirmationValue = smsEnabled && smsPhoneNumber ? formValidatorUtils.getFormattedPhoneNumber(smsPhoneNumber) : email;

    if (typeof window !== 'undefined') {
        HappeningBindings.serviceBookingConfirmationPageLoadAnalytics(activityName, store?.storeId);
    }

    return (
        <Flex
            flexDirection='column'
            gap={[5, null, 6]}
            marginTop={4}
        >
            <Grid gap={[2, 4]}>
                <Flex
                    alignItems='center'
                    gap={2}
                >
                    <Image
                        size={24}
                        src='/img/ufe/filled-checkmark.svg'
                    />
                    <Text
                        is='h1'
                        fontSize={['lg', null, 'xl']}
                        fontWeight='bold'
                        children={getText('youAreBookedTitle')}
                    />
                </Flex>
                <Text
                    is='p'
                    children={getFormattedText(
                        confirmationMethod,
                        false,
                        <Text
                            fontWeight='bold'
                            children={confirmationValue}
                        />
                    )}
                />
            </Grid>
            <Flex
                alignItems={'flex-start'}
                gap={3}
            >
                <HappeningImg
                    borderRadius={2}
                    width={['100%', null, 210]}
                    maxWidth={[105, null, '100%']}
                    alt={activityName}
                    src={imageUrl}
                />
                <Flex
                    gap={4}
                    flexDirection='column'
                >
                    <Grid gap={2}>
                        <div>
                            <Text
                                fontWeight='bold'
                                is='p'
                                children={formattedDate}
                            />
                            <Text
                                fontWeight='bold'
                                is='p'
                                children={formattedTime}
                            />
                        </div>
                        <Text
                            is='p'
                            children={activityName}
                        />
                        <div>
                            <Text
                                is='p'
                                children={getText('artist', [artistName])}
                            />
                            {selectedFeatureToFocus && (
                                <Text
                                    is='p'
                                    children={getText('selectedFeature', [selectedFeatureToFocus])}
                                />
                            )}
                            {specialRequests && (
                                <Text
                                    is='p'
                                    children={getText('specialRequests', [specialRequests])}
                                />
                            )}
                            <Text
                                is='p'
                                children={ensureSephoraPrefix(storeName)}
                            />
                        </div>
                        <Text
                            fontSize='sm'
                            is='p'
                            color={colors.gray}
                            children={`${getText('confirmationNumber')}: ${confirmationNumber}`}
                        />
                    </Grid>
                    <ActionButtons
                        size='sm'
                        width={120}
                        calendarInfo={getCalendarInfo()}
                        reservationDetails={props.bookingInfo}
                        buttons={SERVICE_BUTTONS.UPCOMING}
                        commonButtonProps={{ minWidth: [109, null, 120], maxWidth: [null, null, 120] }}
                    />
                </Flex>
            </Flex>
            <Box
                paddingX={[4, null, 0]}
                paddingY={[2, null, 0]}
                position={['fixed', null, 'relative']}
                right={0}
                bottom={['calc(var(--bottomNavHeight) - 1px)', null, 0]}
                left={0}
                backgroundColor={[colors.white, null, 'inherit']}
                zIndex={['var(--layer-flyout)', null, 'auto']}
                borderBottom={[`${borders[1]} ${colors.lightGray}`, null, 'none']}
                boxShadow={[shadows.light, null, 'none']}
            >
                <Button
                    width={['100%', null, 203]}
                    variant='secondary'
                    children={getText('viewAllReservations')}
                    onClick={e => {
                        locationUtils.navigateTo(e, '/happening/reservations');
                    }}
                />
            </Box>
        </Flex>
    );
}

export default wrapFunctionalComponent(BookingConfirmationDetails, 'BookingConfirmationDetails');
