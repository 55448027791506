/* eslint-disable class-methods-use-this */
import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';

import Modal from 'components/Modal/Modal';
import {
    Box, Text, Button, Flex, Link, Divider
} from 'components/ui';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import { globalModals, renderModal } from 'utils/globalModals';
import {
    colors, space, fontWeights, fontSizes
} from 'style/config';
import PlaceOrderButton from 'components/FrictionlessCheckout/PlaceOrderButton';

const { TERMS_AND_CONDITIONS, TERMS_OF_SERVICE, PRIVACY_POLICY } = globalModals;

class SDUAgreementModal extends BaseClass {
    state = {
        acceptSduTerms: false
    };

    closeSduAgreementModal = () => {
        this.props.showSDUAgreementModal({
            isOpen: false
        });
    };

    render() {
        const {
            isOpen,
            agree,
            toThe,
            sephoraSDU,
            globalModals: globalModalsData,
            showTermsOfServiceModal,
            showTermsAndConditionsModal,
            showPrivacyPolicyModal,
            termsAndConditions,
            agreementText,
            byClicking,
            termsOfService,
            conditionsOfUse,
            privacyPolicy,
            title,
            almostThere,
            placeOrder
        } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                onDismiss={this.closeSduAgreementModal}
                isDrawer={true}
                width={0}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    paddingX={4}
                    paddingBottom={0}
                >
                    <Text
                        is='p'
                        paddingBottom={4}
                        children={almostThere}
                    />
                    <Flex>
                        <Checkbox
                            onClick={() => {
                                this.setState(prevState => {
                                    return {
                                        acceptSduTerms: !prevState.acceptSduTerms
                                    };
                                });
                            }}
                            checked={this.state.acceptSduTerms}
                        >
                            <Text is='p'>
                                <Text
                                    is='span'
                                    css={styles.bold}
                                >
                                    {`${agree} `}
                                </Text>
                                {`${toThe} `}
                                <Text is='span'>{`${sephoraSDU} `}</Text>
                                <Link
                                    css={styles.link}
                                    onClick={() => renderModal(globalModalsData[TERMS_AND_CONDITIONS], showTermsAndConditionsModal)}
                                >
                                    {termsAndConditions}
                                </Link>
                                {`. ${agreementText}`}
                            </Text>
                        </Checkbox>
                    </Flex>
                    <Text
                        is='p'
                        css={styles.bottomText}
                        marginLeft={5}
                    >
                        {`${byClicking} `}
                        <Link
                            css={styles.link}
                            onClick={() => renderModal(globalModalsData[TERMS_OF_SERVICE], showTermsOfServiceModal)}
                        >
                            {termsOfService}
                        </Link>
                        {` ${conditionsOfUse} `}
                        <Link
                            css={styles.link}
                            onClick={() => renderModal(globalModalsData[PRIVACY_POLICY], showPrivacyPolicyModal)}
                        >
                            {privacyPolicy}
                        </Link>
                        .
                    </Text>
                </Modal.Body>
                <Modal.Footer
                    paddingX={0}
                    hasBorder={false}
                >
                    <Divider
                        marginTop={4}
                        css={styles.divider}
                        marginBottom={3}
                    />
                    <Box
                        maxWidth='343px'
                        marginX='auto'
                    >
                        <PlaceOrderButton
                            children={placeOrder}
                            isBopis={this.props.isBopis}
                            canCheckoutPaze={this.props.canCheckoutPaze}
                            isSDUItemInBasket={this.props.isSDUItemInBasket}
                            sduAcceptTerms={this.state.acceptSduTerms}
                            disabled={!this.state.acceptSduTerms}
                            closeSDUModal={this.closeSduAgreementModal}
                        />
                        <Button
                            variant='secondary'
                            width='100%'
                            marginTop={3}
                            onClick={this.closeSduAgreementModal}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Modal.Footer>
            </Modal>
        );
    }
}

const styles = {
    container: {
        fontSize: fontSizes.sm
    },
    bold: {
        fontWeight: fontWeights.bold
    },
    bottomText: {
        marginTop: space[3]
    },
    link: {
        textDecoration: 'underline',
        color: colors.blue
    },
    divider: {
        boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.20)'
    }
};

export default wrapComponent(SDUAgreementModal, 'SDUAgreementModal', true);
