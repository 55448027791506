export default function getResource(label, vars = []) {
    const resources = {
        requiredInformationLabel: '* Required Information',
        recognizedRegisteredEmailMessage: 'We think you already registered for Beauty Insider in a Sephora store because we recognize your email address',
        fillInformationMessage: 'Please fill out the information below to complete your profile.',
        notYouMessage: 'Not you? To clear the registration information and start over',
        notYouClickHereClearLink: 'click here',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        emailAddresLabel: 'Email Address',
        passwordLabel: `Password (${vars[0]} to ${vars[1]} characters)`,
        showPasswordLinkAriaLabel: 'Show password text',
        hidePasswordLinkAriaLabel: 'Hide password text',
        phoneNumber: 'Phone Number',
        staySignedInLabel: 'Keep me signed in',
        joinSephoraLabel: 'Join Sephora Beauty Insider',
        joinSephoraDisclaimer: 'By joining our Beauty insider program, you will earn points on every purchase.',
        zipCodeLabel: 'ZIP/Postal Code (optional)',
        createAccountButtonLabel: 'Create Account',
        registerButtonLabel: 'Register',
        joinNow: 'Join Now',
        completeProfile: 'Complete Profile',
        verified: 'verified',
        justOneMoreStep: 'Just one more step! Create your password and input your information to complete your profile.',
        joinBiFreeShip: 'Join the Beauty Insider loyalty program. Earn points, get *FREE standard shipping*, redeem rewards, and more.',
        useYourPhoneLabel: 'Use your phone number for faster account lookup in store.',
        staySignedInTooltip: 'Selecting this option means you won’t have to sign in as often on this device. For your security, we recommend only doing this on your personal devices.',
        continue: 'Continue',
        registrationComplete: 'Registration Complete',
        confirmMessageBI: 'Congratulations! You are now a Beauty Insider',
        confirmMessage: 'Thank you for registering with Sephora',
        byClicking: `By clicking “${vars[0]}” you acknowledge that you are a U.S. or Canada resident and (1) have read Sephora’s`,
        privacyPolicy: 'Privacy Policy',
        noticeFinancialIncentive: 'Notice of Financial Incentive',
        agreeTo: ' agree to ',
        termsOfUse: 'TERMS OF USE',
        and: ' and ',
        biTerms: 'BEAUTY INSIDER TERMS',
        marketingCheckbox: 'Sign me up for Sephora marketing text alerts.',
        disclosure: 'Sephora Text Alerts Disclosure:',
        byEntering: ' By entering your phone number, checking the',
        signMeUp: ' Sign me up for Sephora marketing text alerts checkbox,',
        clicking: ` clicking the “${vars[0]}” button and confirming sign-up, you consent and agree to receive recurring autodialed marketing texts, including abandoned cart reminders, and confirm that you have read and agree to the`,
        textTerm: ' TEXT TERMS.',
        message: ' Message frequency varies. Consent is not a condition of purchase. Message & data rates may apply. See our ',
        privacy: 'PRIVACY POLICY',
        noticeOf: 'NOTICE OF FINANCIAL INCENTIVE',
        textStop: ' Text STOP to cancel at any time. HELP for help.',
        textStopAddress: ' Sephora: 600 de Maisonneuve Boulevard West, Suite 2400, Montréal, Québec, H3A 3J2, Canada. 1-877-737-4672.',
        receiveOffers: ', and to automatically receive Beauty Insider offers and notifications via email.',
        signInHere: 'Sign in here',
        missingFirstName: 'First name required. Please enter your first name.',
        missingLastName: 'Last name required. Please enter your last name.',
        missingEmail: 'Email required. Please enter your email address. ',
        missingBiId: 'Inputs are missing for creating the user',
        fraudDeclined: 'Please sign in with your existing account.',
        emailFailed: 'An account already exists for the email address you\'ve entered. Please sign in or choose another email address.',
        emailInactive: 'To reactivate the account, please call our Customer Service team at 1-877-SEPHORA (1-877-737-4672) Or Chat with our agents.',
        zipcodeFailed: 'Invalid zip or postal code',
        phoneNumberFailed: 'Invalid phone number',
        genericError: 'We\'re sorry, something went wrong, please try again later.'
    };

    return resources[label];
}
