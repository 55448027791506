import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import { colors, space, mediaQueries } from 'style/config';
import anaUtils from 'analytics/utils';
import Chiclet from 'components/Chiclet';
import Action from 'components/Content/Action';

const ActionChiclet = Action(Chiclet);

function ChicletNav({ menuItems }) {
    const Component = ActionChiclet;

    return menuItems ? (
        <div
            css={styles.chicletWrap}
            data-at={Sephora.debug.dataAt('category_chiclets')}
        >
            {menuItems.map((item, index) => {
                const componentSpecificProps = { action: item.link, children: item.label, dontUseInternalTracking: true };

                return (item.titleText && item.targetUrl) || (item.label && item.link) ? (
                    <Component
                        key={index.toString()}
                        variant='shadow'
                        marginLeft={2}
                        {...componentSpecificProps}
                        onClick={() => {
                            anaUtils.setNextPageData({
                                navigationInfo: anaUtils.buildNavPath(['top nav', componentSpecificProps.children.toLowerCase()]),
                                internalCampaign: item.sid
                            });
                        }}
                        children={componentSpecificProps.children}
                    />
                ) : null;
            })}
        </div>
    ) : null;
}

const styles = {
    chicletWrap: {
        fontSize: 0,
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        padding: space[2],
        paddingLeft: 0,
        textAlign: 'center',
        scrollbarWidth: 'none',
        borderBottom: `1px solid ${colors.lightGray}`,
        '&::-webkit-scrollbar': { display: 'none' },
        [mediaQueries.md]: {
            display: 'none'
        }
    }
};

export default wrapFunctionalComponent(ChicletNav, 'ChicletNav');
