export default function getResource(label) {
    const resources = {
        startEndDates: 'Date de début et date de fin',
        viewRule: 'Afficher la règle',
        reset: 'Réinitialiser',
        conditions: 'Conditions'
    };

    return resources[label];
}
