import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { p13nSelector } from 'selectors/p13n/p13nSelector';
import { coreUserDataSelector } from 'viewModel/selectors/user/coreUserDataSelector';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import FrameworkUtils from 'utils/framework';
import Location from 'utils/Location';
import PersonalizationUtils from 'utils/Personalization';
import contentConstants from 'constants/content';

const { PRODUCT_LIST_VARIANTS } = contentConstants;

const { wrapHOC } = FrameworkUtils;

const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/Content/ProductList/locales', 'ProductList');
const { getPersonalizedComponent } = PersonalizationUtils;

const isElegibleForPersonalization = productList => {
    return (
        !productList.variant || productList.variant === PRODUCT_LIST_VARIANTS.SMALL_GRID || productList.variant === PRODUCT_LIST_VARIANTS.LARGE_GRID
    );
};

const localization = createStructuredSelector({
    showMore: getTextFromResource(getText, 'showMore'),
    rougeBadgeText: getTextFromResource(getText, 'rougeBadge')
});

const fields = createSelector(
    localization,
    p13nSelector,
    coreUserDataSelector,
    (_state, ownProps) => ownProps,
    (locale, p13n, user, productListProps) => {
        let skuList = productListProps.skuList;

        if (Sephora.configurationSettings?.isBirthdayLandingPageP13NEnabled) {
            if (Location.isBirthdayGiftPage() && productListProps.dynamicSorting && isElegibleForPersonalization(productListProps)) {
                // We simulate a personalization object, since the personalization is coming in the heroBanner of the birthday landing page
                const productListPersonalization = {
                    context: productListProps.sid,
                    isEnabled: true
                };

                const personalizedComponent = getPersonalizedComponent(productListPersonalization, user, p13n, true);

                if (personalizedComponent) {
                    skuList = personalizedComponent?.variationData?.skuList || skuList;
                }
            }
        }

        return {
            localization: locale,
            skuList
        };
    }
);

const withProductListProps = wrapHOC(connect(fields));

export { withProductListProps };
