export default function getResource(label) {
    const resources = {
        startEndDates: 'Start Date and End Date',
        viewRule: 'View Rule',
        reset: 'Reset',
        conditions: 'Conditions'
    };

    return resources[label];
}
