import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Actions from 'Actions';
import FrameworkUtils from 'utils/framework';
import { isLMSStoreAPIEnabledSelector } from 'viewModel/selectors/storeAPI/isLMSStoreAPIEnabledSelector';

const { wrapHOC } = FrameworkUtils;

const { showStoreSwitcherModal } = Actions;
const fields = createSelector(isLMSStoreAPIEnabledSelector, isLMSStoreAPIEnabled => {
    return {
        isLMSStoreAPIEnabled
    };
});

const functions = (dispatch, ownProps) => {
    const newFunctions = {};

    if (!ownProps.onDismiss) {
        newFunctions.onDismiss = () => dispatch(showStoreSwitcherModal({ isOpen: false }));
    }

    return newFunctions;
};

const withStoreSwitcherProps = wrapHOC(connect(fields, functions));

export {
    withStoreSwitcherProps, fields, functions
};
