import React from 'react';
import cmsApi from 'services/api/cms';
import store from 'Store';
import actions from 'actions/Actions';
import BCC from 'utils/BCC';
import rrcUtils from 'utils/RrcTermsAndConditions';
import addToBasketActions from 'actions/AddToBasketActions';
import basketUtils from 'utils/Basket';
import UtilActions from 'utils/redux/Actions';

import Modal from 'components/Modal/Modal';
import BccComponentList from 'components/Bcc/BccComponentList/BccComponentList';
import { Text } from 'components/ui';
import RougeRewardCardButtons from 'components/GlobalModals/RougeRewardCardModal/RougeRewardCardButtons/RougeRewardCardButtons';
import localeUtils from 'utils/LanguageLocale';

import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

class RougeRewardCardModal extends BaseClass {
    state = {
        regions: null,
        isOpen: this.props.isOpen,
        sku: this.props.sku
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/GlobalModals/RougeRewardCardModal/locales', 'RougeRewardCardModal');

        return (
            <Modal
                isOpen={this.state.isOpen && !!this.state.regions}
                hasBodyScroll={true}
                onDismiss={this.requestClose}
                dataAt='RougeRewardCardModal'
            >
                <Modal.Header>
                    <Modal.Title>{getText('biTermsAndConditions')}</Modal.Title>
                </Modal.Header>

                <Modal.Body maxHeight={300}>{this.getContent()}</Modal.Body>
                <Modal.Footer>
                    <Text
                        is='p'
                        lineHeight='tight'
                    >
                        <Text
                            fontWeight='bold'
                            css={{ textTransform: 'uppercase' }}
                        >
                            {getText('termsAndConditions')}
                        </Text>{' '}
                        <Text color='gray'>{getText('required')}</Text>
                    </Text>
                    <RougeRewardCardButtons
                        callback={this.props.callback}
                        currentSku={this.state.sku}
                        originalContext={this.props.analyticsContext}
                        isRougeExclusiveCarousel={this.props.isRougeExclusiveCarousel}
                    />
                </Modal.Footer>
            </Modal>
        );
    }

    componentDidMount() {
        //Logic to determine whether the terms are checked or not goes here.
        const areTermsAndConditionsAccepted = rrcUtils.areRRCTermsConditionsAccepted();

        if (areTermsAndConditionsAccepted) {
            store.dispatch(UtilActions.merge('termsConditions', 'isRRCTermsAndConditions', areTermsAndConditionsAccepted));
        }

        store.setAndWatch('basket.items', this, data => {
            // Check if RougeRewardCard item has been removed from basket
            if (basketUtils.itemListHasRougeRewardCard(this.state.items) && !basketUtils.itemListHasRougeRewardCard(data.items)) {
                store.dispatch(
                    addToBasketActions.showError([], null, [
                        {
                            type: 'rougeRewardCardRemoval',
                            message: `Rouge Reward has been removed from your basket as
                        you have not accepted the Terms and Conditions. If you would
                        like to redeem it, please add it to basket again and accept
                        the Terms and Conditions.`
                        }
                    ])
                );
            }

            this.setState({ items: data.items });
        });

        store.setAndWatch('termsConditions.isRRCTermsAndConditions', this, data => {
            this.setState({
                acceptTerms: data.isRRCTermsAndConditions,
                showError: !data.isRRCTermsAndConditions
            });
        });

        if (this.state.regions === null) {
            this.getMediaContent(BCC.MEDIA_IDS.BI_TERMS_AND_CONDITIONS);
        }
    }

    getMediaContent = mediaId => {
        cmsApi.getMediaContent(mediaId).then(data => {
            this.setState({ regions: data.regions });
        });
    };

    getContent = () => {
        const regions = this.state.regions;

        if (regions && regions.content && regions.content.length) {
            return <BccComponentList items={regions.content} />;
        }

        return null;
    };

    requestClose = () => {
        let rougeRewardCard;
        const areTermsAndConditionsAccepted = rrcUtils.areRRCTermsConditionsAccepted();

        // If the user did not accept Terms and Conditions, remove Rouge Reward Card from basket
        if (!areTermsAndConditionsAccepted) {
            rougeRewardCard = basketUtils.getRougeRewardCardFromBasket();

            if (rougeRewardCard) {
                store.dispatch(addToBasketActions.removeItemFromBasket(rougeRewardCard, true, true));
            }
        }

        store.dispatch(
            actions.showRougeRewardCardModal({
                isOpen: false,
                sku: null
            })
        );
    };
}

export default wrapComponent(RougeRewardCardModal, 'RougeRewardCardModal');
