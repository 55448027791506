import { SET_RMN_BANNERS_MAIN, CLEAR_RMN_BANNERS } from 'constants/actionTypes/rmnBanners';

export const updateRmnMainBanners = data => ({
    type: SET_RMN_BANNERS_MAIN,
    payload: {
        rmnBanners: data
    }
});

export const clearRmnBanners = () => ({
    type: CLEAR_RMN_BANNERS,
    payload: {
        rmnBanners: null
    }
});
