import SmartChat from 'components/SmartChat/SmartChat';
import withAfterEventsRendering from 'hocs/withAfterEventsRendering';
import withGlobalModals from 'hocs/withGlobalModals';
import Location from 'utils/Location';
import ChatUtils from 'utils/HomeChat';

const eventsToWait = !Sephora.isNodeRender && Location.isCustomerServicePage() ? ['HydrationFinished'] : ['PostLoad', 'HydrationFinished'];

const ConnectedSmartChat = ChatUtils.shouldShowSmartChat(Sephora.pagepath)
    ? withAfterEventsRendering(withGlobalModals(SmartChat), eventsToWait)
    : null;

export default ConnectedSmartChat;
