const ACTION_TYPES = {
    UPDATE_RECOMMENDATIONS: 'UPDATE_RECOMMENDATIONS',
    UPDATE_REQUEST_DATA: 'UPDATE_REQUEST_DATA'
};

const initialState = {
    constructorRecommendations: {},
    constructorRequestData: {
        params: {},
        podId: '',
        isCollection: false
    }
};

const reducer = function (state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_RECOMMENDATIONS: {
            const data = action.payload;
            const {
                podId, constructorTitle, constructorRecommendations, isError = false, isEmpty = false, totalResults, resultId
            } = data;

            return Object.assign({}, state, {
                constructorRecommendations: {
                    ...state.constructorRecommendations,
                    [podId]: {
                        constructorTitle,
                        constructorRecommendations,
                        isError,
                        isEmpty,
                        totalResults,
                        resultId
                    }
                }
            });
        }
        case ACTION_TYPES.UPDATE_REQUEST_DATA: {
            return Object.assign({}, state, {
                constructorRequestData: action.payload
            });
        }
        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
