const resources = {
    holdAtLocation: 'Ou expédier à un lieu de ramassage',
    changeAlternatePickup: 'Changer l’autre lieu de ramassage',
    shipToFedex: 'Expédition à un lieu de ramassage FedEx',
    selectLocationNearYou: 'Sélectionner un emplacement près de chez vous',
    orShipToFedexLocation: 'Ou expédier à un lieu de ramassage FedEx près de chez vous'
};

export default function getResource(label) {
    return resources[label];
}
