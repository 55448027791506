import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import { isSLSTestEnabledSelector } from 'viewModel/selectors/slsApi/isSLSTestEnabledSelector';

const { wrapHOC } = FrameworkUtils;

const fields = createSelector(isSLSTestEnabledSelector, isSLSTestEnabled => ({ isSLSTestEnabled }));
const functions = {};
const withViewAllLovesProps = wrapHOC(connect(fields, functions));

export {
    fields, functions, withViewAllLovesProps
};
