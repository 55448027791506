export default function getResource(label, vars = []) {
    const resources = {
        clientDetails: 'Renseignements sur le ou la membre de la clientèle',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        emailAddress: 'Adresse de courriel',
        textNotifications: 'Notifications par textos',
        phoneNumber: 'Numéro de téléphone',
        smsReminder: 'Oui, veuillez m’envoyer des rappels par message texte au sujet de mon rendez-vous.',
        textNotificationsAgreement: `J’accepte les ${vars[0]} et j’autorise Sephora à utiliser un système automatisé, un composeur automatique, un système automatisé pour la sélection ou la composition de numéros de téléphone, un système de composition téléphonique automatique (« ATDS ») ou tout autre type de système, de logiciel, de matériel, ou machine (peu importe la classification) qui peut utiliser une procédure ou un processus automatisé pour envoyer des messages texte concernant ma commande au numéro de téléphone cellulaire fourni. Le consentement n’est pas une condition d’achat. Des frais de messagerie texte et de données peuvent s’appliquer. Consultez notre ${vars[1]}.`,
        textTerms: 'MODALITÉS DES TEXTOS',
        privacyPolicy: 'Politique de confidentialité',
        bookNow: 'Réserver',
        worCheckboxText: `En cochant cette case, je reconnais avoir lu attentivement et compris dans leur intégralité toutes les dispositions du document ${vars[0]}, j’accepte volontairement et sciemment le risque et je renonce à mes droits tels que décrits.`,
        worLinkText: 'Renonciation de droits et acceptation des risques',
        worMinorCheckboxText: 'Je prends rendez-vous pour une personne âgée de moins de 18 ans.',
        worParentCheckboxText: `En cochant cette case, je reconnais être le parent ou le tuteur légal d’une personne mineure qui reçoit les services de Sephora. Je reconnais avoir lu attentivement et compris dans leur intégralité toutes les dispositions du document ${vars[0]}, j’accepte volontairement et sciemment les risques et je renonce à mes droits tels que décrits.`,
        validCreditCard: `Pour garantir cette réservation, une carte de crédit valable est requise. Le paiement complet, y compris les taxes, sera effectué en magasin en utilisant votre mode de paiement préféré. Le pourboire peut être ajouté après le service. En passant cette commande, j’accepte la ${vars[0]}.`,
        iAcknowledge: `Je reconnais et j’accepte les modalités suivantes : ${vars[0]}, ${vars[1]}, ${vars[2]}.`,
        paymentPolicy: 'Politique relative au paiement',
        onTimePolicy: 'Politique relative à la ponctualité',
        cancelationPolicy: 'Politique d’annulation',
        defaultMethod: 'Méthode par défaut',
        securityCode: 'Code de sécurité',
        weRequireAWaiver: 'Nous avons besoin d’une renonciation de droits',
        chooseOne: 'Choisissez-en une',
        waiverCheckboxesErrorText: 'Pour réserver, veuillez confirmer et cocher l’option appropriée ci-dessous.',
        policiesCheckboxErrorText: 'Pour réserver, veuillez confirmer et cocher les politiques ci-dessous.',
        confirm: 'Confirmer',
        payment: 'Paiement',
        change: 'Modifier',
        invalidPhoneNumberError: 'Veuillez saisir un numéro de téléphone cellulaire valable.',
        phoneInfoText: 'Au cas où nous aurions besoin de communiquer avec vous au sujet de votre service.',
        securityCodeInputError: 'Le code de sécurité est incorrect',
        deleteCard: 'Supprimer la carte',
        removeCardMessage: 'Souhaitez-vous vraiment supprimer cette carte?',
        yes: 'Oui',
        no: 'Non',
        genericBookingApiError: 'Votre demande de réservation n’a pas pu être complétée pour le moment. Veuillez réessayer plus tard.',
        genericCreditCardApiError: 'Nous n’avons pas été en mesure de valider votre carte de crédit. Veuillez entrer à nouveau les renseignements relatifs à votre carte de crédit ou utiliser une autre carte.',
        timeSlotAlreadyTakenErr: 'Nous sommes désolés, mais il semble que quelqu’un d’autre vient de réserver la plage horaire sélectionnée. Cela peut parfois se produire lorsqu’une autre personne complète sa réservation pendant que vous faites votre sélection.',
        securityCodeNotConfirmed: 'Appuyez sur « Confirmer » pour poursuivre la réservation.',
        cardNotSelectedErr: 'Veuillez appuyer sur « Utiliser cette carte » pour procéder à la réservation.',
        initialDataLoadError: 'Nous ne pouvons pas récupérer de données de carte de crédit pour le moment. Veuillez réessayer plus tard.',
        timeSlotUnavailable: 'Plage horaire non disponible',
        selectNewTimeSlot: 'Sélectionner une nouvelle plage horaire',
        worModalTitle: 'Renonciation de droits',
        timeSlotAlreadyTaken: 'Nous sommes désolés, mais il semble que quelqu’un d’autre vient de réserver la plage horaire sélectionnée. Cela peut parfois se produire lorsqu’une autre personne complète sa réservation pendant que vous faites votre sélection.',
        whatYouCanDo: 'Ce que vous pouvez faire',
        chooseAnotherTime: 'Choisir une autre heure',
        returnToSchedule: 'Cliquez sur « Sélectionner une nouvelle plage horaire » pour revenir au calendrier et choisir une nouvelle heure qui vous convient.',
        joinWaitlist: 'Rejoindre la liste d’attente',
        joinWaitlistInfo: 'S’il n’y a pas de disponibilités, cliquez sur « Rejoindre la liste d’attente » pour la plage horaire que vous préférez et nous vous enverrons un courriel si une disponibilité s’ouvre en raison d’annulations.',
        declineAndCancelWaitlist: 'Refuser et annuler la liste d’attente'
    };

    return resources[label];
}
