import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import PlaceOrderButton from 'components/FrictionlessCheckout/PlaceOrderButton/PlaceOrderButton';
import OrderActions from 'actions/OrderActions';
import Actions from 'Actions';

const { wrapHOC } = FrameworkUtils;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;

const getText = getLocaleResourceFile('components/FrictionlessCheckout/PlaceOrderButton/locales', 'PlaceOrderButton');

const textResources = createStructuredSelector({
    denialMessage: getTextFromResource(getText, 'denialMessage'),
    placeOrder: getTextFromResource(getText, 'placeOrder'),
    items: getTextFromResource(getText, 'items'),
    item: getTextFromResource(getText, 'item'),
    orderTotal: getTextFromResource(getText, 'orderTotal')
});

const functions = {
    showSDUAgreementModal: Actions.showSDUAgreementModal
};

const connectedPlaceOrderButton = connect(
    createSelector(textResources, texts => {
        const { togglePlaceOrderDisabled } = OrderActions;

        return {
            ...texts,
            getText,
            togglePlaceOrderDisabled,
            showSDUAgreementModal: Actions.showSDUAgreementModal
        };
    }),
    functions
);

const withPlaceOrderButtonProps = wrapHOC(connectedPlaceOrderButton);

export default withPlaceOrderButtonProps(PlaceOrderButton);
