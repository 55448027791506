const RUPEX_FORM_RESPONSE_TYPES = {
    ERROR: 'rupex_error',
    RESPONSE: 'rupex_response',
    RESIZE: 'rupex_resize',
    READY: 'rupex_ready',
    SUBMIT: 'rupex_form_submit'
};

const PREVIEW_COOKIE_VALUES = {
    ASSETS_ALL: 'all',
    ASSETS_ACTIVE: 'act',
    IN_STOCK: 'ins',
    OUT_OF_STOCK: 'oos'
};

export {
    RUPEX_FORM_RESPONSE_TYPES, PREVIEW_COOKIE_VALUES
};
