const SET_TAX_CLAIM_DATA = 'SET_TAX_CLAIM_DATA';
const SET_START_APPLICATION = 'SET_START_APPLICATION';
const ADD_WIZARD_FORM_DATA = 'ADD_WIZARD_FORM_DATA';
const FETCH_ORDER_DETAILS_SUCCESS = 'FETCH_ORDER_DETAILS_SUCCESS';
const FETCH_ORDER_DETAILS_ERROR = 'FETCH_ORDER_DETAILS_ERROR';
const UPDATE_STEP4_DATA = 'UPDATE_STEP4_DATA';
const TAX_INIT_SUCCESS = 'TAX_INIT_SUCCESS';
const TAX_INIT_ERROR = 'TAX_INIT_ERROR';
const SUBMIT_FINAL_TAX_FORM_ERROR = 'SUBMIT_FINAL_TAX_FORM_ERROR';
const SUBMIT_FINAL_TAX_FORM_SUCCESS = 'SUBMIT_FINAL_TAX_FORM_SUCCESS';
const FETCH_ELIGIBLE_ORDERS_ERROR = 'FETCH_ELIGIBLE_ORDERS_ERROR';
const FETCH_ELIGIBLE_ORDERS_SUCCESS = 'FETCH_ELIGIBLE_ORDERS_SUCCESS';
const UPDATE_SELECTED_ORDERS = 'UPDATE_SELECTED_ORDERS';

export {
    SET_TAX_CLAIM_DATA,
    SET_START_APPLICATION,
    ADD_WIZARD_FORM_DATA,
    FETCH_ORDER_DETAILS_SUCCESS,
    FETCH_ORDER_DETAILS_ERROR,
    UPDATE_STEP4_DATA,
    TAX_INIT_SUCCESS,
    TAX_INIT_ERROR,
    SUBMIT_FINAL_TAX_FORM_ERROR,
    SUBMIT_FINAL_TAX_FORM_SUCCESS,
    FETCH_ELIGIBLE_ORDERS_ERROR,
    FETCH_ELIGIBLE_ORDERS_SUCCESS,
    UPDATE_SELECTED_ORDERS
};
