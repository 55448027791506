import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import constants from 'constants/content';
import Banner from 'components/Content/Banner';
import { setIntersectionObserver } from 'utils/intersectionObserver';
import BaseClass from 'components/BaseClass';
import bannerListLayout from 'components/Content/BannerListLayout';
import { UserInfoReady } from 'constants/events';
import { Text } from 'components/ui';

const { SmallCarousel, LargeCarousel, GridLayout } = bannerListLayout;

const { CONTEXTS, COMPONENT_SPACING } = constants;

const GAPS = {
    SMALL: 'small',
    LARGE: 'large'
};

const VARIANTS = {
    'Small Carousel': SmallCarousel,
    'Large Carousel': LargeCarousel,
    '2-Column': GridLayout,
    '3-Column': GridLayout,
    '4-Column': GridLayout
};

class BannerList extends BaseClass {
    ref = React.createRef();
    constructor() {
        super();
        this.state = {
            inView: false,
            inViewObserver: null
        };
    }

    componentDidUpdate(prevProps) {
        // Wait until the personalization data has been initialized to make sure
        // we have all p13n contexts to compare our banners against

        if (!prevProps.p13n.isInitialized && this.props.p13n.isInitialized) {
            this.setUpViewableImpression();
        }
    }

    componentDidMount() {
        const { items, variant, triggerCMSImpression } = this.props;

        if (this.props.p13n.isInitialized) {
            this.setUpViewableImpression();
        }

        if (triggerCMSImpression && variant.indexOf('Carousel') === -1) {
            triggerCMSImpression && triggerCMSImpression(Array.from(Array(items.length).keys()));
        }
    }

    componentWillUnmount() {
        if (this.state.inViewObserver) {
            this.state.inViewObserver.disconnect();
        }
    }

    setUpViewableImpression = () => {
        // The impression event for SOT needs user data, so we need it to be ready before we trigger the impression.
        Sephora.Util.onLastLoadEvent(window, [UserInfoReady], () => {
            // The impression should only be triggered when the banner list is in the viewport.
            setIntersectionObserver(
                this.ref.current,
                (inView, observer) => {
                    if (inView) {
                        this.props.triggerImpression();
                    }

                    this.setState({
                        inViewObserver: observer
                    });
                },
                {},
                true
            );
        });
    };

    componentWillUnmount() {
        if (this.state.inViewObserver) {
            this.state.inViewObserver.disconnect();
        }
    }

    render() {
        const {
            items, width, largeWidth, enablePageRenderTracking, variant, page, subtitle, title
        } =
            this.props.personalizedComponent?.variationData || this.props;

        // Personalization of this component will be handled by NBC.
        // `BannerList` won't be personalized if `personalization.isNBCEnabled` is false even when `personalization.isEnabled` is true.
        const isSkeleton = this.props.personalization?.isNBCEnabled && this.props.isPersonalizationInitializing;

        if (!items || !width) {
            return null;
        }

        const itemWidth = width && largeWidth ? [width, largeWidth] : width;
        const LayoutBannerList = VARIANTS[variant];
        const hasValidTitle = title && title !== this.props.sid && title !== this.props.type;

        const banners = [];
        items
            .filter(item => item.media || item.text)
            .forEach((banner, index) => {
                let { mediaPlacement, largeMediaPlacement } = banner;

                if (banner.variant !== 'Icon') {
                    if (mediaPlacement === 'left') {
                        mediaPlacement = 'top';
                    }

                    if (largeMediaPlacement === 'left') {
                        largeMediaPlacement = 'top';
                    }

                    if (mediaPlacement === 'right') {
                        mediaPlacement = 'bottom';
                    }

                    if (largeMediaPlacement === 'right') {
                        largeMediaPlacement = 'bottom';
                    }
                }

                banners.push(
                    <Banner
                        {...banner}
                        position={index}
                        key={banner.sid}
                        mediaPlacement={mediaPlacement}
                        largeMediaPlacement={largeMediaPlacement}
                        marginTop={null}
                        marginBottom={null}
                        size={LayoutBannerList === GridLayout ? '100%' : itemWidth}
                        context={CONTEXTS.BANNER_LIST}
                        enablePageRenderTracking={enablePageRenderTracking && index <= (Sephora.isMobile() ? 1 : 2)}
                        isRootComponent={false}
                        page={page}
                        fireBannerListEvent={async target => {
                            await this.props.triggerClick(target, index);
                        }}
                        inList={true}
                        isSkeleton={isSkeleton}
                        referer={this.props.sid}
                    />
                );
            });

        return (
            <>
                {hasValidTitle && (
                    <Text
                        is='h2'
                        fontSize={['md', 'lg']}
                        fontWeight='bold'
                        children={title}
                        marginTop={this.props.marginTop}
                    />
                )}
                {subtitle && (
                    <Text
                        is='p'
                        lineHeight='tight'
                        children={subtitle}
                        marginTop={1}
                    />
                )}

                <LayoutBannerList
                    {...this.props}
                    ref={this.ref}
                    banners={banners}
                    onImpression={this.props.triggerCMSImpression}
                    {...((subtitle || hasValidTitle) && {
                        marginTop: 4
                    })}
                />
            </>
        );
    }
}

BannerList.propTypes = {
    context: PropTypes.oneOf([CONTEXTS.CONTAINER, CONTEXTS.MODAL]).isRequired,
    sid: PropTypes.string,
    enablePageRenderTracking: PropTypes.bool,
    gap: PropTypes.oneOf([GAPS.SMALL, GAPS.LARGE]),
    items: PropTypes.array,
    width: PropTypes.number.isRequired,
    largeWidth: PropTypes.number,
    marginTop: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    marginBottom: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    p13n: PropTypes.object.isRequired,
    variant: PropTypes.string
};

BannerList.defaultProps = {
    sid: null,
    enablePageRenderTracking: null,
    gap: GAPS.SMALL,
    items: null,
    largeWidth: null,
    marginTop: COMPONENT_SPACING.SM,
    marginBottom: COMPONENT_SPACING.LG,
    variant: 'Large Carousel'
};

export default wrapComponent(BannerList, 'BannerList', true);
