export default function getResource(label, vars = []) {
    const resources = {
        youAreBookedTitle: 'You\'re booked!',
        emailConfirmation: `You\'ll receive a confirmation email at ${vars[0]}.`,
        phoneConfirmation: `You’ll receive a confirmation text at ${vars[0]}.`,
        artist: `Artist: ${vars[0]}`,
        selectedFeature: `Selected Feature: ${vars[0]}`,
        specialRequests: `Special Requests: ${vars[0]}`,
        confirmationNumber: 'Confirmation Number',
        addToCalendarButton: 'Add To Calendar',
        rescheduleButton: 'Reschedule',
        cancelButton: 'Cancel',
        viewAllReservations: 'View All Reservations',
        areYouSure: 'Are you sure you want to cancel?',
        areYouSureWithin24Hours: `Are you sure you want to cancel? Canceling within 24 hours of your appt will incur a ${vars[0]} charge.`,
        seePolicies: 'See policies',
        moreDetails: 'for more details.',
        cancelService: 'Cancel Service',
        no: 'No',
        yes: 'Yes'
    };

    return resources[label];
}
