import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import ConstructorRecsSelector from 'selectors/constructorRecs/constructorRecsSelector';
import preferredStoreInfoSelector from 'selectors/user/preferredStoreInfoSelector';
import ConstructorRecsActions from 'actions/ConstructorRecsActions';
import { showBasketGreyBackgroundSelector } from 'viewModel/selectors/testTarget/showBasketGreyBackgroundSelector';
import Location from 'utils/Location';

const { wrapHOC } = FrameworkUtils;
const { constructorRecsSelector } = ConstructorRecsSelector;
const { updateRequestData } = ConstructorRecsActions;
const fields = createSelector(
    constructorRecsSelector,
    preferredStoreInfoSelector,
    showBasketGreyBackgroundSelector,
    (constructorRecs = {}, preferredStoreInfo, showBasketGreyBackground) => {
        return {
            constructorRecs,
            preferredStoreId: preferredStoreInfo?.storeId || null,
            showBasketGreyBackground: Location.isBasketPage() && showBasketGreyBackground
        };
    }
);

const functions = {
    updateRequestData
};

const withConstructorCarouselProps = wrapHOC(connect(fields, functions));

export {
    withConstructorCarouselProps, fields, functions
};
