import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import { Grid, Text, Flex } from 'components/ui';
import uiUtils from 'utils/UI';
import {
    radii, space, fontSizes, lineHeights, mediaQueries
} from 'style/config';
import Location from 'utils/Location';
import contentConsts from 'constants/content';

const {
    RECAP_CAROUSEL: { ITEM_URLS }
} = contentConsts;

const { SKELETON_ANIMATION, SKELETON_COPY } = uiUtils;

function RecapItem({
    targetUrl, isLoading, displayTitle, title, sid, children, triggerClick, showUpdateReadyToCheckout
}) {
    if (showUpdateReadyToCheckout && targetUrl.includes(ITEM_URLS.BASKET)) {
        styles.cardTitle.marginBottom = 0;
    }

    return (
        <Flex
            role='button'
            onClick={async e => {
                e.preventDefault();
                triggerClick && (await triggerClick(sid));
                Location.navigateTo(e, targetUrl);
            }}
            flexDirection='column'
            lineHeight='tight'
            textAlign='left'
            backgroundColor='white'
            borderRadius={2}
            boxShadow='light'
            padding={[3, 4]}
            fontSize={['sm', 'base']}
            css={styles.root}
        >
            {(displayTitle || title) && (
                <div css={[styles.cardTitle, isLoading && SKELETON_COPY]}>
                    <Text
                        is='h3'
                        fontWeight='bold'
                        fontSize={['sm', 'base']}
                        numberOfLines={2}
                    >
                        <span>{displayTitle || title}</span>
                    </Text>
                </div>
            )}
            {isLoading ? (
                <Grid
                    columns={2}
                    gap={[1, 2]}
                >
                    <div css={styles.skeleton.item} />
                    <div css={styles.skeleton.item} />
                    <div css={styles.skeleton.item} />
                    <div css={styles.skeleton.item} />
                </Grid>
            ) : (
                children
            )}
        </Flex>
    );
}

const styles = {
    root: {
        '.no-touch &': {
            transition: 'transform .2s',
            '&:hover': {
                transform: `translateY(-${space[1]}px)`
            }
        }
    },
    skeleton: {
        item: [
            SKELETON_ANIMATION,
            {
                paddingBottom: '100%',
                borderRadius: radii[2]
            }
        ]
    },
    cardTitle: {
        marginBottom: space[2],
        height: fontSizes.sm * lineHeights.tight * 2,
        [mediaQueries.sm]: {
            height: fontSizes.base * lineHeights.tight * 2
        }
    }
};

RecapItem.propTypes = {
    isLoading: PropTypes.bool
};

RecapItem.defaultProps = {
    isLoading: true
};

export default wrapFunctionalComponent(RecapItem, 'RecapItem');
