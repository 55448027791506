/* eslint-disable object-curly-newline */

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { breakpoints, mediaQueries, site, space, colors } from 'style/config';
import store from 'store/Store';
import actions from 'Actions';
import { Box, Flex, Grid, Link, Text, Icon, Button, Divider } from 'components/ui';
import Modal from 'components/Modal/Modal';
import Dropdown from 'components/Dropdown/Dropdown';
import ProductImage from 'components/Product/ProductImage/ProductImage';
import ProductVariation from 'components/Product/ProductVariation/ProductVariation';
import Price from 'components/Product/Price/Price';
import AddToBasketButton from 'components/AddToBasketButton';
import CountCircle from 'components/CountCircle';
import OnlyFewLeftFlag from 'components/OnlyFewLeftFlag/OnlyFewLeftFlag';
import SaleFlag from 'components/SaleFlag/SaleFlag';
import SeeProductDetails from 'components/SeeProductDetails';
import basketUtils from 'utils/Basket';
import userUtils from 'utils/User';
import auth from 'utils/Authentication';
import analyticsUtils from 'analytics/utils';
import processEvent from 'analytics/processEvent';
import mediaUtils from 'utils/Media';
import localeUtils from 'utils/LanguageLocale';
import skuUtils from 'utils/Sku';
import anaConsts from 'analytics/constants';
import Location from 'utils/Location';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import Storage from 'utils/localStorage/Storage';
import { DebouncedResize, TestTargetReady, UserInfoReady } from 'constants/events';
import { HEADER_VALUE } from 'constants/authentication';

const LOVES_URL = '/shopping-list';
const { ADD_TO_BASKET_TYPES: ADD_BUTTON_TYPE } = basketUtils;
const { Media } = mediaUtils;
const {
    ASYNC_PAGE_LOAD,
    PAGE_TYPES: { LOVES_MODAL },
    LinkData: { LOVES_MODAL_VIEW_ALL }
} = anaConsts;

const getText = text => localeUtils.getLocaleResourceFile('components/Header/InlineLoves/locales', 'InlineLoves')(text);

const signIn = () => {
    auth.requireAuthentication(null, null, null, null, false, HEADER_VALUE.USER_CLICK).catch(() => {});
};

const trackViewAllClick = () => {
    const prop55 = LOVES_MODAL_VIEW_ALL;
    digitalData.page.attributes.previousPageData.linkData = prop55;
    let recentEvent;

    // Checking if the loves modal is for small viewport only where loves modal shows from bottom.
    if (!window.matchMedia(breakpoints.smMin).matches) {
        recentEvent = analyticsUtils.getLastAsyncPageLoadData({ pageType: LOVES_MODAL });
    }

    analyticsUtils.setNextPageData({
        ...recentEvent,
        linkData: prop55
    });
};

const handleLinkClick = (e, targetUrl, toggleModal) => {
    toggleModal && toggleModal();
    Location.navigateTo(e, targetUrl);
};

function renderHeader(
    showLink,
    getTextString,
    totalNotifications,
    shouldDisplayOAFLProducts = false,
    shouldDisplaySaleProducts = false,
    shouldDisplayFavBrandsSpoke = false
) {
    return (
        <React.Fragment>
            <Flex
                justifyContent='space-between'
                alignItems='center'
            >
                <Flex>
                    <Text
                        is='h2'
                        fontWeight='bold'
                        display={totalNotifications > 0 && 'flex'}
                        alignItems={totalNotifications > 0 && 'center'}
                        data-at={Sephora.debug.dataAt('loves_title')}
                    >
                        {getText(getTextString)}
                    </Text>
                    {totalNotifications > 0 && (
                        <Box
                            paddingRight={30}
                            paddingLeft={10}
                        >
                            <CountCircle
                                top={0}
                                right={0}
                                position='static'
                                key={`inlineLovesCount${totalNotifications}`}
                                children={totalNotifications}
                            />
                        </Box>
                    )}
                </Flex>
                {showLink && (
                    <Link
                        href={LOVES_URL}
                        color='blue'
                        padding={2}
                        margin={-2}
                        onClick={trackViewAllClick}
                        children={getText('viewAll')}
                        data-at={Sephora.debug.dataAt('view_all_btn')}
                    />
                )}
            </Flex>
            {shouldDisplayFavBrandsSpoke && renderLGUIFavBrands()}
            <Divider
                marginTop={4}
                marginBottom={shouldDisplayOAFLProducts || shouldDisplaySaleProducts ? 4 : 3}
                marginX={-4}
            />
        </React.Fragment>
    );
}

function renderNoLoves(isAnonymous) {
    return (
        <React.Fragment>
            <Media greaterThan='xs'>{renderHeader(false, 'recentlyLoved', undefined, undefined, undefined, false)}</Media>
            {!isAnonymous && renderLGUIFavBrands()}
            <Box
                textAlign='center'
                paddingTop={!isAnonymous ? [1, 4] : 3}
                paddingBottom={2}
            >
                <Text
                    is='p'
                    marginBottom={4}
                    fontWeight='bold'
                    children={getText('noLovesDesc')}
                    data-at={Sephora.debug.dataAt('empty_message')}
                />
                {isAnonymous ? (
                    <Grid
                        gap={3}
                        columns={2}
                    >
                        <Button
                            onClick={signIn}
                            block={true}
                            variant='primary'
                            size='sm'
                            children={getText('signInButton')}
                            data-at={Sephora.debug.dataAt('sign_in_loves')}
                        />
                        <Button
                            onClick={() => store.dispatch(actions.showRegisterModal({ isOpen: true, openPostBiSignUpModal: true }))}
                            block={true}
                            variant='secondary'
                            size='sm'
                            children={getText('createAccountButton')}
                            data-at={Sephora.debug.dataAt('create_account_loves')}
                        />
                    </Grid>
                ) : (
                    <Button
                        href='/'
                        hasMinWidth={true}
                        variant='primary'
                        size='sm'
                        children={getText('shopNow')}
                    />
                )}
            </Box>
        </React.Fragment>
    );
}

const renderFavBrands = (marginX = 0) => {
    return (
        <React.Fragment>
            <Box
                backgroundColor={colors.lightBlue}
                borderRadius={2}
                paddingX={3}
                paddingY={2}
                marginTop={3}
                marginX={marginX}
            >
                <Flex
                    flexDirection='column'
                    fontSize='sm'
                >
                    {getText('lookingForFavBrands')}
                    <Link
                        color='blue'
                        underline={true}
                        children={getText('goToBeautyPrefs')}
                        href='/profile/BeautyPreferences'
                    />
                </Flex>
            </Box>
        </React.Fragment>
    );
};

const renderLGUIFavBrands = () => <Media greaterThan='xs'>{renderFavBrands()}</Media>;

const renderCTA = (item, toggleModal) => {
    return item.isExternallySellable ? (
        <SeeProductDetails
            variant={'secondary'}
            size={'sm'}
        />
    ) : (
        <AddToBasketButton
            sku={item}
            variant={ADD_BUTTON_TYPE.SECONDARY}
            analyticsContext={LOVES_MODAL}
            isAddButton={true}
            callback={toggleModal ? () => toggleModal() : null}
            size='sm'
        />
    );
};

const renderLovesList = (loves, toggleModal, showLovesListSaleNotification = false) => {
    return loves.map((item, index) => (
        <React.Fragment key={`inlineLovesItem_${item.skuId}`}>
            {index > 0 && (
                <Divider
                    marginY={3}
                    marginX={-4}
                />
            )}
            <Link
                href={item.targetUrl}
                display='block'
                hoverSelector='.Link-target'
                onClick={e => handleLinkClick(e, item.targetUrl, toggleModal)}
                data-at={Sephora.debug.dataAt('loves_item')}
            >
                <Grid
                    columns='auto 1fr 88px'
                    alignItems='flex-start'
                >
                    <ProductImage
                        id={item.skuId}
                        skuImages={item.skuImages}
                        size={48}
                        disableLazyLoad={true}
                    />
                    <Box fontSize='sm'>
                        <Text
                            className='Link-target'
                            display='block'
                            fontWeight='bold'
                            numberOfLines={1}
                            children={item.brandName}
                            data-at={Sephora.debug.dataAt('item_brand')}
                        />
                        <Text
                            className='Link-target'
                            display='block'
                            children={item.productName}
                            data-at={Sephora.debug.dataAt('item_name')}
                        />
                        <ProductVariation
                            sku={item}
                            fontSize='sm'
                            marginTop='.25em'
                        />
                        {showLovesListSaleNotification && item?.salePrice && <SaleFlag marginRight='4px' />}
                        {item?.isOnlyFewLeft && <OnlyFewLeftFlag />}
                        <Price
                            includeValue={false}
                            sku={item}
                            marginTop='.25em'
                        />
                    </Box>
                    {skuUtils.isCountryRestricted(item) ? (
                        <Text
                            color='gray'
                            fontSize='sm'
                            textAlign='center'
                            data-at={Sephora.debug.dataAt('restriction_message')}
                        >
                            {getText('itemShip')} {localeUtils.isCanada() ? 'Canada' : 'the United States'}
                        </Text>
                    ) : (
                        renderCTA(item, toggleModal)
                    )}
                </Grid>
            </Link>
        </React.Fragment>
    ));
};

function renderLoves({
    loves,
    toggleModal,
    onlyAFewLeftInLovesList = [],
    onSaleInLovesList = [],
    shouldDisplayOAFLProducts,
    shouldDisplaySaleProducts,
    showLovesListSaleNotification,
    totalNotifications = 0
}) {
    let displayedLoves = shouldDisplayOAFLProducts ? loves.filter(love => !onlyAFewLeftInLovesList.includes(love)) : loves;
    displayedLoves = shouldDisplaySaleProducts ? displayedLoves.filter(love => !onSaleInLovesList.includes(love)) : displayedLoves;
    displayedLoves = totalNotifications >= 3 ? displayedLoves.slice(0, 2) : displayedLoves.slice(0, 5 - totalNotifications);
    const combinedSaleAndOnlyAFewLeftList = [...onlyAFewLeftInLovesList, ...(shouldDisplaySaleProducts ? onSaleInLovesList : [])];
    const displayedSaleAndOnlyFewLovesList = totalNotifications > 3 ? combinedSaleAndOnlyAFewLeftList.slice(0, 3) : combinedSaleAndOnlyAFewLeftList;
    const headerCopy = !shouldDisplayOAFLProducts && shouldDisplaySaleProducts ? 'onSaleNow' : 'getTheseBeforeTheyAreGone';

    return (
        <>
            {(shouldDisplayOAFLProducts || shouldDisplaySaleProducts) && (
                <>
                    <Media greaterThan='xs'>
                        {renderHeader(
                            true,
                            'loves',
                            undefined,
                            shouldDisplayOAFLProducts,
                            shouldDisplaySaleProducts,
                            shouldDisplayOAFLProducts || shouldDisplaySaleProducts
                        )}
                        {renderHeader(false, headerCopy, totalNotifications, shouldDisplayOAFLProducts, shouldDisplaySaleProducts)}
                    </Media>
                    <Media at='xs'>{renderHeader(true, headerCopy, totalNotifications, shouldDisplayOAFLProducts, shouldDisplaySaleProducts)}</Media>
                    {renderLovesList(displayedSaleAndOnlyFewLovesList, toggleModal, showLovesListSaleNotification)}
                    <Divider
                        marginY={4}
                        marginX={-4}
                    />
                </>
            )}
            {renderHeader(
                !shouldDisplayOAFLProducts && !shouldDisplaySaleProducts,
                'recentlyLoved',
                null,
                null,
                null,
                !shouldDisplayOAFLProducts && !shouldDisplaySaleProducts
            )}
            {renderLovesList(displayedLoves, toggleModal)}
        </>
    );
}

class InlineLoves extends BaseClass {
    state = {
        isOpen: false,
        isAnonymous: null,
        isLovesListPage: false,
        shouldDisplayOAFLProducts: false,
        shouldDisplaySaleProducts: false,
        shouldDisplayNotification: false,
        showLovesListSaleNotification: false,
        totalOnlyAFewLeftLoves: 0,
        totalOnSaleLoves: 0
    };

    trackNavClick = () => {
        const navigationInfo = analyticsUtils.buildNavPath(['top nav', 'loves icon']);
        const smallViewport = !window.matchMedia(breakpoints.smMin).matches;

        if (smallViewport) {
            const pageType = LOVES_MODAL;
            const pageDetail = 'lists-loves modal';
            const pageName = `${pageType}:${pageDetail}:n/a:*`;
            // eslint-disable-next-line no-undef
            const previousPageName = digitalData.page.attributes.sephoraPageInfo.pageName;
            processEvent.process(ASYNC_PAGE_LOAD, {
                data: {
                    pageName,
                    pageType,
                    pageDetail,
                    previousPageName,
                    navigationInfo
                }
            });
        } else {
            analyticsUtils.setNextPageData({ navigationInfo });
        }
    };

    toggleModal = e => {
        e && e.preventDefault();
        this.setState({
            isOpen: !this.state.isOpen,
            isModalOpen: !this.state.isModalOpen
        });
    };

    handleTrigger = isDropdownOpen => () => {
        this.setState({
            isOpen: isDropdownOpen
        });
    };

    handleMouseEnter = () => {
        if (!Sephora.isMobile()) {
            this.setState({ isHover: true });
        }
    };

    handleMouseLeave = () => {
        this.setState({ isHover: false });
    };

    render() {
        const { isOpen, isLovesListPage, shouldDisplayNotification, totalNotifications, isAnonymous, isHover } = this.state;

        return isLovesListPage ? (
            this.getIcon()
        ) : (
            <React.Fragment>
                <Media
                    greaterThan='xs'
                    css={{ height: '100%' }}
                >
                    <Dropdown
                        id='loves_drop'
                        hasMaxHeight={true}
                        mouseEnterCallback={this.mouseEnterCallback}
                        position='static'
                        onTrigger={this.handleTrigger}
                        height='100%'
                        data-at={Sephora.debug.dataAt('loves_flyout')}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <Dropdown.Trigger
                            zIndex={isOpen && 1}
                            aria-label={getText('loves')}
                            href={LOVES_URL}
                            onClick={this.trackNavClick}
                            css={styles.anchor}
                        >
                            {/* improve hover usability */}
                            <span
                                style={!isOpen ? { display: 'none' } : null}
                                css={{
                                    position: 'absolute',
                                    left: '50%',
                                    bottom: -32,
                                    marginLeft: -32,
                                    width: 64,
                                    height: 64,
                                    transform: 'rotate(-45deg)'
                                }}
                            />
                            <div css={styles.iconWrap}>
                                <Icon
                                    data-at={Sephora.debug.dataAt('love_icon_large')}
                                    name={isOpen || isHover ? 'heart' : 'heartOutline'}
                                />
                                {shouldDisplayNotification || this.props.shouldShowTotalLoves ? (
                                    <CountCircle
                                        aria-label={totalNotifications === 1 ? getText('item') : getText('items')}
                                        key={`inlineLovesCount${totalNotifications}`}
                                        data-at={Sephora.debug.dataAt('loves_count')}
                                        children={totalNotifications || this.props.totalLoves}
                                    />
                                ) : null}
                            </div>
                        </Dropdown.Trigger>
                        <Dropdown.Menu
                            width={this.props.dropWidth}
                            align='right'
                        >
                            {this.getContent()}
                        </Dropdown.Menu>
                    </Dropdown>
                </Media>
                <Media at='xs'>
                    {this.getIcon('small', this.toggleModal)}
                    <Modal
                        isOpen={this.state.isModalOpen}
                        isDrawer={true}
                        onDismiss={this.toggleModal}
                        style={!this.state.isModalOpen ? { display: 'none' } : undefined}
                    >
                        <Modal.Header>
                            <Modal.Title children={getText('loves')} />
                        </Modal.Header>
                        <Modal.Body
                            paddingX={null}
                            paddingTop={null}
                            paddingBottom={null}
                        >
                            {!isAnonymous && renderFavBrands(4)}
                            {this.getContent()}
                        </Modal.Body>
                    </Modal>
                </Media>
            </React.Fragment>
        );
    }

    getIcon = (view, onClick) => {
        const { isOpen, isLovesListPage, shouldDisplayNotification, totalNotifications } = this.state;

        const WrapEl = isLovesListPage ? 'div' : 'a';

        return (
            <WrapEl
                {...(isLovesListPage || {
                    ['aria-label']: getText('loves'),
                    href: LOVES_URL,
                    onClick: e => {
                        onClick && onClick(e);
                        this.trackNavClick();
                    }
                })}
                css={styles.anchor}
            >
                <div
                    onMouseEnter={this.mouseEnterCallback}
                    css={shouldDisplayNotification && styles.iconWrap}
                >
                    <Icon
                        data-at={Sephora.debug.dataAt(`love_icon${view ? '_' + view : ''}`)}
                        name={isOpen || isLovesListPage ? 'heart' : 'heartOutline'}
                    />
                    {shouldDisplayNotification && !isLovesListPage ? (
                        <CountCircle
                            aria-label={totalNotifications === 1 ? getText('item') : getText('items')}
                            key={`inlineLovesCount${totalNotifications}`}
                            data-at={Sephora.debug.dataAt('loves_count')}
                            children={totalNotifications}
                        />
                    ) : null}
                </div>
            </WrapEl>
        );
    };

    getContent = () => {
        const { loves, onlyAFewLeftInLovesList, onSaleInLovesList } = this.props;
        const { shouldDisplayOAFLProducts, shouldDisplaySaleProducts, showLovesListSaleNotification, totalNotifications } = this.state;

        return (
            <Box
                lineHeight='tight'
                paddingX={4}
                paddingTop={4}
                paddingBottom={5}
            >
                {loves?.length > 0
                    ? renderLoves({
                        loves,
                        toggleModal: this.state.isModalOpen ? this.toggleModal : null,
                        onlyAFewLeftInLovesList,
                        onSaleInLovesList,
                        shouldDisplayOAFLProducts,
                        shouldDisplaySaleProducts,
                        showLovesListSaleNotification,
                        totalNotifications
                    })
                    : renderNoLoves(this.state.isAnonymous)}
            </Box>
        );
    };

    mouseEnterCallback = () => {
        const { shouldDisplayNotification } = this.state;
        const isOnlyAFewLeftNotificationHidden = Storage.session.getItem(LOCAL_STORAGE.HIDE_OAFL_NOTIFICATION);

        if (shouldDisplayNotification && !isOnlyAFewLeftNotificationHidden) {
            this.setState({
                shouldDisplayNotification: false
            });
            Storage.session.setItem(LOCAL_STORAGE.HIDE_OAFL_NOTIFICATION, true);
        }
    };

    handleResize = () => {
        // close the modal if resizing above `xs` breakpoint
        if (this.state.isModalOpen) {
            if (window.matchMedia(breakpoints.smMin).matches) {
                this.toggleModal();
            }
        }
    };

    componentDidMount() {
        if (Location.isLovesListPage()) {
            this.setState({
                isLovesListPage: true
            });
        }

        store.setAndWatch(
            'user',
            this,
            () => {
                this.setState({
                    isAnonymous: userUtils.isAnonymous()
                });
            },
            true
        );

        Sephora.Util.onLastLoadEvent(window, [UserInfoReady], () => {
            const showLoveListNotification = !(localeUtils.isCanada() && !Sephora.isMobile());

            if (showLoveListNotification) {
                const { onlyAFewLeftInLovesList } = this.props;
                const totalOnlyAFewLeftLoves = onlyAFewLeftInLovesList?.length;
                const totalNotifications = totalOnlyAFewLeftLoves;
                const isOnlyAFewLeftNotificationHidden = Storage.session.getItem(LOCAL_STORAGE.HIDE_OAFL_NOTIFICATION);
                const shouldDisplayNotification = totalOnlyAFewLeftLoves > 0 && !isOnlyAFewLeftNotificationHidden;
                const shouldDisplayOAFLProducts = totalOnlyAFewLeftLoves > 0;

                this.setState({
                    totalOnlyAFewLeftLoves,
                    totalNotifications,
                    shouldDisplayNotification,
                    shouldDisplayOAFLProducts
                });
            }
        });

        Sephora.Util.onLastLoadEvent(window, [UserInfoReady, TestTargetReady], () => {
            store.setAndWatch('testTarget', this, newOffers => {
                const showLovesListSaleNotification = !!newOffers.testTarget.offers?.lovesListSaleNotification?.show;

                if (showLovesListSaleNotification) {
                    const { onSaleInLovesList, onlyAFewLeftInLovesList } = this.props;
                    const totalOnSaleLoves = onSaleInLovesList?.length;
                    const totalOnlyAFewLeftLoves = onlyAFewLeftInLovesList?.length;
                    const totalNotifications = totalOnSaleLoves + totalOnlyAFewLeftLoves;
                    const isLovesNotificationHidden = Storage.session.getItem(LOCAL_STORAGE.HIDE_OAFL_NOTIFICATION);
                    const shouldDisplayNotification = totalOnSaleLoves > 0 && !isLovesNotificationHidden;
                    const shouldDisplayOAFLProducts = totalOnlyAFewLeftLoves > 0;
                    const shouldDisplaySaleProducts = totalOnSaleLoves > 0;

                    this.setState({
                        totalOnSaleLoves,
                        totalNotifications,
                        shouldDisplayNotification,
                        shouldDisplayOAFLProducts,
                        shouldDisplaySaleProducts,
                        showLovesListSaleNotification
                    });
                }
            });
        });

        window.addEventListener(DebouncedResize, this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener(DebouncedResize, this.handleResize);
    }
}

const styles = {
    anchor: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: site.headerHeight,
        paddingLeft: space[2],
        paddingRight: space[2],
        lineHeight: 0,
        [mediaQueries.sm]: {
            height: '100%',
            paddingTop: space[4],
            paddingBottom: space[4],
            paddingLeft: space[3],
            paddingRight: space[3]
        }
    },
    iconWrap: { position: 'relative' }
};

export default wrapComponent(InlineLoves, 'InlineLoves', true);
