/* eslint-disable ssr-friendly/no-dom-globals-in-react-cc-render */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import {
    Box, Button, Text, Flex, Divider, Image
} from 'components/ui';
import Pagination from 'components/ProductPage/Pagination/Pagination';
import { DebouncedResize } from 'constants/events';
import { breakpoints, zIndices } from 'style/config';
import userUtils from 'utils/User';
import ReactDOM from 'react-dom';
import dateUtils from 'utils/Date';
import localeUtils from 'utils/LanguageLocale';

const getText = localeUtils.getLocaleResourceFile('components/PersonalizedPreviewPlacements/VariationCarousel/locales', 'VariationCarousel');

class VariationCarousel extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            isLoading: true
        };
        this.modalRef = React.createRef();
    }

    checkIsMobile = () => {
        this.setState({
            isMobile: window.matchMedia(breakpoints.smMax).matches,
            isLoading: false
        });
    };

    componentDidMount() {
        this.checkIsMobile();
        window.addEventListener(DebouncedResize, this.checkIsMobile);
        window.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener(DebouncedResize, this.checkIsMobile);
        window.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (!this.modalRef.current?.contains(event.target)) {
            this.props.onClose();
        }
    };

    renderuleConditions = rule => {
        const conjuction = rule?.conj === 'any' ? 'ANY (OR)' : 'ALL (AND)';

        return (
            <Box marginTop={2}>
                {rule.isPrioritizationTypeStandard && (
                    <Text
                        is='p'
                        children={conjuction}
                    />
                )}
                {rule?.conditions?.map(item => (
                    <Text
                        is='p'
                        marginTop={2}
                        marginLeft={4}
                        children={item.replace(/[@#$%^"&*()]/g, '')}
                    />
                ))}
                <Text
                    is='p'
                    marginTop={4}
                    children={getText('startEndDates')}
                    fontWeight='bold'
                />
                <Text
                    is='p'
                    children={`${dateUtils.getDateInDMYwithTimeFormat(rule.dates.startDate)} - ${dateUtils.getDateInDMYwithTimeFormat(
                        rule.dates.endDate
                    )}`}
                    marginTop={2}
                />
            </Box>
        );
    };

    renderNumberedCarousel = (variations, top, left) => {
        return (
            <Box
                position='absolute'
                top={`${top}px`}
                left={`${left}px`}
                zIndex={zIndices.max}
            >
                <Box
                    height={'fit-content'}
                    border={1}
                    backgroundColor='#06E3FF'
                    width={'375px'}
                >
                    <Flex
                        justifyContent='space-between'
                        padding={4}
                    >
                        <Text
                            children={this.props.sid}
                            fontWeight='bold'
                            fontSize='md'
                            maxWidth={'300px'}
                        />
                        <button
                            type='button'
                            onClick={this.props.onClose}
                        >
                            <Image
                                src='/img/ufe/x-button.svg'
                                alt='close'
                            />
                        </button>
                    </Flex>
                    <Divider color='white' />
                    <Box margin={4}>
                        <Pagination
                            totalPages={variations?.length}
                            currentPage={this.props.activePage}
                            customPageNumberStyle={{
                                backgroundColor: '#FFFFFF'
                            }}
                            justifyContent='space-around'
                            handlePageClick={this.props.onClick}
                            minNumberOfPages={0}
                        />
                        <Flex justifyContent='space-between'>
                            <Button
                                children={getText('reset')}
                                variant='secondary'
                                marginTop={4}
                                width={'164px'}
                                onClick={this.props.resetVariation}
                                disabled={this.props.activePage === 0}
                            />
                            <Button
                                children={getText('viewRule')}
                                variant='primary'
                                marginTop={4}
                                width={'164px'}
                                onClick={this.props.viewRuleClick}
                                disabled={this.props.activePage === 0}
                            />
                        </Flex>
                    </Box>
                </Box>
            </Box>
        );
    };

    renderRuleDetailBox = (rule, top, left) => {
        return (
            <Box
                position={this.state.isMobile ? 'fixed' : 'absolute'}
                top={!this.state.isMobile && `${top}px`}
                bottom={this.state.isMobile && '0px'}
                left={!this.state.isMobile && `${left + (userUtils.isAnonymous() ? 400 : 0)}px`}
                zIndex={zIndices.max}
            >
                <Box
                    height={'fit-content'}
                    backgroundColor='#FFFFFF'
                    width={['100vw', '375px']}
                    css={{
                        boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)'
                    }}
                >
                    <Flex
                        justifyContent='space-between'
                        padding={4}
                    >
                        <Text
                            children={rule.name}
                            fontWeight='bold'
                            fontSize='md'
                            maxWidth={'300px'}
                        />
                        <button
                            type='button'
                            onClick={this.props.OnRuleClose}
                        >
                            <Image
                                src='/img/ufe/x-button.svg'
                                alt='close'
                            />
                        </button>
                    </Flex>
                    <Divider />
                    <Box padding={4}>
                        <Text
                            children={getText('conditions')}
                            fontWeight='bold'
                            marginTop={4}
                        />
                        {this.renderuleConditions(rule)}
                    </Box>
                </Box>
            </Box>
        );
    };

    renderModal = () => {
        const { position, variations, isRuleDetailBoxOpen, rule } = this.props;
        let top, left;

        // we need to calc window.scrollY and scrollX as we need to display the modal
        // rendered right below the info icon placement.
        if (this.state.isMobile) {
            left = 4;
            top = position?.top + position?.height + window?.scrollY + 10;
        } else {
            top = position?.top + position?.height + window?.scrollY + 10;
            left = position.left + window?.scrollX;
        }

        return (
            <div ref={this.modalRef}>
                {userUtils.isAnonymous() && this.renderNumberedCarousel(variations, top, left)}

                {isRuleDetailBoxOpen && this.renderRuleDetailBox(rule, top, left)}
            </div>
        );
    };

    render() {
        if (this.state.isLoading) {
            return null;
        }

        return <div>{ReactDOM.createPortal(this.renderModal(), document.querySelector('body'))}</div>;
    }
}

export default wrapComponent(VariationCarousel, 'VariationCarousel');
