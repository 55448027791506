import getBiRewardsGroup from 'services/api/beautyInsider/getBiRewardsGroup';
import BIApi from 'services/api/beautyInsider';
import UtilActions from 'utils/redux/Actions';
import Storage from 'utils/localStorage/Storage';
import biUtils from 'utils/BiProfile';

const { REWARDS_PURCHASED_GROUPS } = biUtils;

function fetchProfileRewards(options) {
    return dispatch => {
        getBiRewardsGroup.getBiRewardsGroupForCheckout(options).then(data => dispatch(UtilActions.merge('rewards', 'rewards', data)));
    };
}

function fetchRecentlyRedeemedRewards(userId, options) {
    return dispatch => {
        BIApi.getPurchaseHistory(userId, options).then(data => {
            const { useLXSRedemptionHistory = false } = Sephora.configurationSettings;

            if (useLXSRedemptionHistory) {
                Storage.local.setItem(REWARDS_PURCHASED_GROUPS, data.purchasedGroups);
            }

            dispatch(UtilActions.merge('rewards', 'rewardsPurchasedGroups', data.purchasedGroups));
        });
    };
}

export default {
    fetchProfileRewards,
    fetchRecentlyRedeemedRewards
};
