import RmnBanner from 'components/Rmn/RmnBanner/RmnBanner';
import RmnBannerStyle from 'components/Rmn/RmnBanner/RmnBannerStyle';
import RmnMainBanner from 'components/Rmn/RmnBanner/layouts/RmnMainBanner';
import RmnSideBanner from 'components/Rmn/RmnBanner/layouts/RmnSideBanner';

export {
    RmnBannerStyle, RmnMainBanner, RmnSideBanner
};

export default RmnBanner;
