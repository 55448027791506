export default function getResource(label, vars = []) {
    const resources = {
        transactionTaxRefunds: 'Programme de remboursement et d’exemption de taxes',
        taxExemptionCategorySublabel: 'Veuillez sélectionner une catégorie.',
        taxExemption: 'Exemption de taxes',
        categoryStepTitle: 'Catégorie d’exemption de taxes',
        categoryStepDisplayName: 'Option à sélectionner',
        categoryStepSubtitle: 'Veuillez choisir une catégorie',
        uploadStepTitle: 'Téléverser le(s) document(s)',
        uploadStepDisplayName: 'Documents',
        orderNumberInputStepTitle: 'Numéro de commande',
        orderNumberInputStepDisplayName: 'Numéro de commande',
        orderNumberInputStepPlaceholder: 'Numéro de commande',
        orderTableLabelOrderNumber: 'Numéro de commande',
        orderTableLabelOrderDate: 'Date de la commande',
        orderTableLabelSelected: 'Sélectionné',
        orderTableSelctionError: 'Vous avez sélectionné le maximum de 10 commandes pour cette demande d’exemption de taxes. Si vous souhaitez sélectionner une autre commande, vous devez d’abord désélectionner l’une des commandes choisies avant d’effectuer une modification.',
        taxExemptionInfoStepTitle: 'Renseignements sur le compte d’exemption de taxes',
        taxExemptionInfoStepDisplayName: 'Renseignements sur l’exemption de taxes',
        additionalCommentsStepTitle: 'Commentaires supplémentaires',
        additionalCommentsStepDisplayName: 'Commentaires supplémentaires',
        additionalCommentsStepPlaceholder: 'Veuillez décrire votre situation ici.',
        additionalCommentsStepCopy: 'Veuillez fournir tout renseignement supplémentaire qui vous aidera à expliquer votre situation. Y a-t-il d’autres renseignements qui nous aideront à traiter votre exemption de taxes?',
        finalReviewStepTitle: 'Révision finale',
        finalReviewStepDisplayName: 'Révision finale',
        finalReviewStepCopy: 'Veuillez passer en revue les renseignements que vous avez fournis ci-dessus et accepter les conditions d’utilisation et la politique de confidentialité pour poursuivre.',
        finalReviewStepErrorText: 'Veuillez passer en revue et cocher les cases ci-dessous pour continuer.',
        generalInfo: 'Renseignements généraux avant de commencer',
        uploadAction: 'Téléverser',
        nextAction: 'Suivant',
        editAction: 'Modifier',
        submitAction: 'Soumettre',
        dateColumnTitle: 'Date',
        maxDocumentsHint: 'Documents (3 max.)',
        documentsNote: 'Veuillez noter que si votre document d’exemption est incomplet (p. ex. : signature ou date manquante, cases applicables non cochées), vous devrez soumettre de nouveau le document avec tous les renseignements nécessaires.',
        documentsContrains: 'Veuillez téléverser un fichier .jpeg, .jpg, .png, .img ou .pdf dont la taille n’excède pas 4 Mo.',
        documentsIndications: 'Veuillez téléverser le recto et le verso du document si celui-ci est recto verso.',
        invalidFile: 'Le fichier n’a pas pu être téléversé. Veuillez vérifier la taille du fichier.',
        missingFreightForwarder: 'Sélectionnez l’une des options suivantes',
        categoryTitleForIA: 'Communauté autochtone',
        categoryTitleForR: 'Distributeur',
        categoryTitleForNPRCO: 'Organismes sans but lucratif, religieux ou de bienfaisance',
        categoryTitleForDVIFO: 'Anciens combattants handicapés et famille immédiate en Oklahoma',
        categoryTitleForESFFF: 'Vente d’exportation par transitaire de la Floride',
        categoryTitleForSLGEI: 'Gouvernements d’États et locaux et établissements d’enseignement',
        categoryTitleForFNM: 'Membres des Premières Nations',
        categoryTitleForFA: 'Membres des Premières Nations',
        categoryTitleForO: 'Autre',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        email: 'Adresse courriel',
        startApplication: 'Commencer la demande',
        documentsLabelForIA: 'Veuillez téléverser votre numéro d’identification de tribu',
        documentsLabelForFA: 'Veuillez téléverser votre numéro d’identification de tribu et autres documents justificatifs.',
        documentsLabelForDVIFO: 'Veuillez téléverser votre carte d’exemption de la taxe de vente; vous pourriez être exempté de la taxe de vente sur les achats effectués dans l’État de l’Oklahoma.',
        documentsLabelForNPRCO: 'Veuillez téléverser une lettre émise par l’État confirmant votre statut d’exemption de taxes ou le certificat d’exemption délivré par l’État et que votre commande a été payée par l’organisation et expédiée à celle-ci.',
        documentsLabelForR: 'Veuillez téléverser un certificat de revente valide délivré par l’État qui confirme l’État de destination et que votre commande est expédiée à votre entreprise et payée par celle-ci.',
        documentsLabelForSLGEI: 'Veuillez télécharger le certificat d’exemption délivré par l’État ou tout autre document. Téléversez une confirmation que votre commande est payée par l’entité gouvernementale ou l’établissement d’enseignement et expédiée à celle-ci.',
        documentsLabelForSLGEI2: 'Veuillez téléverser un certificat d’exemption valide pour l’État destinataire ou une lettre de l’État désignant votre entité gouvernementale ou établissement d’enseignement comme exonéré d’impôt.',
        documentsLabelForESFFF: 'Si votre achat a été exporté à l’extérieur des États-Unis par l’intermédiaire d’un transitaire de la Floride avec la documentation appropriée, votre achat peut être exonéré de taxes.',
        documentsLabelForO: 'Veuillez téléverser le(s) document(s) d’exemption de taxes applicable(s) à votre demande de remboursement.',
        firstNameInputLabel: 'Prénom',
        lastNameInputLabel: 'Nom de famille',
        emailInputLabel: 'Adresse courriel',
        tribeNameInputLabel: 'Nom de la tribu',
        tribeIdNumberInputLabel: 'Numéro d’identification de la tribu (le cas échéant)',
        reserveNameInputLabel: 'Nom de la communauté où votre commande a été livrée',
        idCardLabel: 'Carte d’identité',
        issueDateInputLabel: 'Date d’émission',
        expirationDateInputLabel: 'Date d’expiration',
        expirationDateInvalid: 'La date d’expiration doit être postérieure à la date d’émission',
        none: 'Aucune',
        exemptionNumberLabel: 'Numéro d’exemption',
        exemptionNumberPlaceholder: 'EXM-XXXXXXXX-XX',
        effectiveDateLabel: 'Date d’entrée en vigueur',
        effectiveDatePlaceholder: 'MM/JJ/AAAA',
        addressSubLabel: 'Adresse',
        streetAddress: 'Adresse',
        address2Label: 'Avertisseur de porte, code du bâtiment, numéro d’appartement…',
        zipCode: 'Code postal',
        city: 'Ville',
        state: 'État/Région',
        addressTitle: 'Adresse',
        taxAddress1Empty: 'L’adresse municipale est requise. Veuillez saisir la rue de votre adresse.',
        taxAddress2Empty: 'Une ligne d’adresse supplémentaire est requise. Veuillez entrer les renseignements de votre appartement, de votre suite ou d’une autre adresse.',
        taxCityEmpty: 'La ville est requise. Veuillez saisir votre ville.',
        taxStateEmpty: 'L’État est requis. Veuillez sélectionner votre État.',
        taxZipCodeEmpty: 'Un code postal est requis. Veuillez saisir votre code postal.',
        taxZipCodeInvalid: 'Un code postal valide est requis. Veuillez entrer un code postal valide.',
        orderNumberNotFound: 'Ce numéro de commande n’existe pas. Veuillez essayer de nouveau.',
        taxAdditionalCommentsEmpty: 'Veuillez entrer tout renseignement supplémentaire qui pourrait nous être utile lors de l’examen de votre demande.',
        registrationNumber: 'Numéro d’enregistrement',
        alias: 'Alias',
        registryGroupNumber: 'Numéro de registre du groupe',
        registryBandName: 'Nom de la bande au registre',
        nameOfReservation: 'Nom de la communauté où votre commande a été livrée',
        datesLabel: 'Dates',
        dateLabelFreightForwarder: 'Certificat',
        registrationNumberEmpty: 'Numéro d’enregistrement requis. Veuillez entrer votre numéro d’enregistrement.',
        registrationNumberInvalid: 'Veuillez entrer un numéro d’enregistrement valide.',
        registryGroupEmpty: 'Veuillez entrer le numéro de registre du groupe.',
        registryBandNameEmpty: 'Veuillez entrer le nom de registre de votre bande.',
        nameOfReservationEmpty: 'Veuillez entrer le nom de votre communauté.',
        dateRangeInvalid: 'La date d’expiration doit être postérieure à la date d’émission.',
        noAdditionalComments: 'Aucune',
        organizationLabel: 'Organisation',
        organizationAddressLabel: 'Adresse du siège social de l’organisation',
        organizationPositionLabel: 'Votre poste au sein de l’organisation',
        organizationNameLabel: 'Nom de l’organisation exonérée de taxe',
        organizationUrlLabel: 'URL de l’organisation',
        stateIssuedTaxExemptNumberLabel: 'Numéro d’exemption de taxe émis par l’État',
        phoneNumberLabel: 'Numéro de téléphone',
        creditCardSecondTitle: 'Carte de crédit utilisée pour l’achat',
        creditCardIssued: 'Cette carte de crédit a-t-elle été émise par l’organisation?',
        yes: 'Oui',
        no: 'Non',
        invalidCreditCardIssued: 'Veuillez faire un choix.',
        fixErrorsBelow: 'Veuillez vérifier et corriger les erreurs ci-dessous.',
        viewPositionLabel: 'Poste',
        viewOrganizationNameLabel: 'Nom de l’organisation',
        viewOrganizationUrlLabel: 'URL de l’organisation',
        viewStateIssuedTaxExemptNumberLabel: 'Numéro d’exemption de taxe émis par l’État',
        viewOrganizationPhoneNumberLabel: 'Numéro de téléphone de l’organisation',
        viewHeadquarterAddressLabel: 'Adresse du siège social',
        viewNameOnCreditCardLabel: 'Nom figurant sur la carte de crédit',
        viewCreditCardIssuedLabel: 'Cette carte de crédit a-t-elle été émise par l’organisation?',
        freightForwarderNameInputLabel: 'Nom du transitaire',
        freightForwarderCertNumberInputLabel: 'Numéro de certificat du transitaire',
        freightForwarderNameEmpty: 'Le nom du transitaire est requis. Veuillez entrer le nom du transitaire.',
        freightForwarderNameInvalid: 'Le nom du transitaire n’est pas valide. Veuillez entrer un nom de transitaire alphanumérique valide.',
        freightForwarderCertEmpty: 'Le numéro de certificat est requis. Veuillez entrer le numéro de certificat.',
        freightForwarderCertInvalid: 'Le certificat du transitaire n’est pas valide. Veuillez entrer un nom de transitaire alphanumérique valide.',
        certIssueDateLabel: 'Date d’émission du certificat',
        certExpirationDateLabel: 'Date d’expiration du certificat',
        idCardIssueDateLabel: 'Date d’émission de la carte d’identité',
        idCardExpirationDateLabel: 'Date d’expiration de la carte d’identité',
        tribeNameLabel: 'Nom de la tribu',
        tribeIdLabel: 'Numéro d’identification de la tribu',
        tribeReserveNameLabel: 'Nom de la communauté où votre commande a été livrée',
        tribeReserveNameEmpty: 'Veuillez entrer la communauté.',
        tribeNameEmpty: 'Veuillez entrer le nom de la tribu.',
        businessPositionLabel: 'Votre poste au sein de l’entreprise',
        businessNameLabel: 'Nom de l’entreprise',
        businessTypeLabel: 'Type d’entreprise',
        businessUrlLabel: 'URL de l’entreprise',
        stateSalesTaxPermitNumberLabel: 'Numéro de permis de taxe de vente de l’État',
        businessLabel: 'Renseignements commerciaux',
        businessAddressLabel: 'Adresse du siège social de l’entreprise',
        formSubmittedStepSubtitle: 'Nous avons reçu votre demande!',
        formSubmittedStepCopy: 'Les documents ont été soumis avec succès. Vous recevrez des mises à jour sur votre demande par courriel dans un délai de 7 à 10 jours ouvrables.',
        submissionError: 'Erreur de soumission',
        systemMaintenanceError: 'Entretien du système',
        networkError: 'Erreur de réseau',
        sessionExpiredError: 'Session expirée',
        modalButtonTextReview: 'Commentaire',
        documentNotUploaded: 'Une erreur s’est produite avec le document. Veuillez passer en revue le type et la taille du document.',
        unsupportedDocumentType: 'Une erreur s’est produite avec le document. Veuillez passer en revue le type et la taille du document.',
        orderNotFound: 'Le numéro de commande est introuvable. Veuillez passer en revue la demande et la soumettre de nouveau.',
        genericTaxApiError: 'Une erreur technique s’est produite. Veuillez passer en revue votre demande pour repérer les erreurs et réessayez.',
        applicationExists: 'Si vous avez récemment soumis une demande, vous devez attendre que l’équipe ait terminé l’examen de cette demande avant d’en commencer une nouvelle.',
        unknownError: 'Une erreur s’est produite. Veuillez réessayer ou revenir plus tard.',
        viewMore: 'Voir plus',
        viewLess: 'Voir moins',
        select: 'Sélectionner',
        deselect: 'Désélectionner',
        taxExemptAddress: 'Adresse d’exemption de taxes',
        taxExemptAddressSubtitle: 'L’exemption de taxes ne peut être appliquée qu’à une seule adresse pour le moment.',
        clientOrderNotFound: 'Désolé, vous ne pouvez pas commencer une demande d’exemption de taxes en raison de l’absence de commandes en ligne récentes.',
        taxExemptionTitle: 'Voulez-vous enregistrer un statut d’exemption de taxes à une adresse?',
        taxExemptionConfirmTitle: 'Oui, j’aimerais enregistrer l’adresse du statut d’exemption de taxes dans mon profil.',
        taxExemptionConfirmSubtitle: 'Sélectionnez cette option si vous souhaitez enregistrer ou remplacer l’adresse actuelle d’exemption de taxes dans votre profil. Une fois que votre adresse est authentifiée comme valide pour l’exemption de taxes, vous pourrez recevoir une exemption de taxes pour toutes les futures commandes expédiées à cette adresse.',
        taxExemptionDenyTitle: 'Non, je veux seulement demander un remboursement d’exemption de taxes.',
        taxExemptionDenySubtitle: 'Sélectionnez cette option si vous souhaitez soumettre une demande d’exemption de taxes sans enregistrer l’adresse dans votre profil.',
        taxExemptionMissingSelection: 'Veuillez faire un choix.',
        existingAddressLabel: 'Sélectionnez une adresse existante',
        addAddressLabel: 'Ajouter une adresse de livraison',
        genericError: 'Un problème est survenu. Veuillez revenir et réessayer plus tard.',
        setDefaultAddress: 'Définissez cette adresse comme adresse par défaut.'
    };

    return resources[label];
}
