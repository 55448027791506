import { createSelector } from 'reselect';
import { showSelector } from 'selectors/testTarget/offers/lmsApi/lmsStoreAPI/show/showSelector';
import { isTestTargetReadySelector } from 'viewModel/selectors/testTarget/isTestTargetReadySelector';

const isLMSStoreAPIEnabledSelector = createSelector(isTestTargetReadySelector, showSelector, (testReady, show) => {
    const globalKillswitch = !!Sephora.configurationSettings?.isLMSStoreAPIEnabled;
    const isLMSStoreAPIEnabled = globalKillswitch && testReady && show;

    return isLMSStoreAPIEnabled;
});

export { isLMSStoreAPIEnabledSelector };
