import ufeApi from 'services/api/ufeApi';
import RCPSCookies from 'utils/RCPSCookies';
import NGPCookies from 'utils/NGPCookies';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+Profile+API

function getPublicProfileByNickname(nickname) {
    let url = `/api/users/profiles/${nickname}?source=public`;

    if (RCPSCookies.isRCPSProfileInfoGroupAPIEnabled()) {
        url = `/gway/v2/users/profiles/${nickname}?source=public`;
    }

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

function getCurrentProfileEmailSubscriptionStatus() {
    const url = '/api/users/profiles/current?propertiesToInclude=emailSubscriptionInfo';

    return ufeApi
        .makeRequest(url, { method: 'GET' })
        .then(data =>
            data.errorCode ? Promise.reject(data) : data.emailSubscriptionInfo && data.emailSubscriptionInfo.subscriptionStatus === 'SUBSCRIBED'
        );
}

export function lookupProfileByLogin(email, source = 'lookup') {
    const isNGPUserRegistrationEnabled = NGPCookies.isNGPUserRegistrationEnabled();
    const url = isNGPUserRegistrationEnabled ? '/gway/v1/dotcom/auth/v1/user/lookup' : '/api/users/profiles';

    return (
        ufeApi
            .makeRequest(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    source
                })
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            .then(data => (data.profileId ? data : Promise.reject()))
    );
}

export function getProfileForPasswordReset(email) {
    const url = '/api/users/profiles';

    return ufeApi
        .makeRequest(url, {
            method: 'PUT',
            body: JSON.stringify({
                email,
                source: 'resetPassword'
            })
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default {
    getPublicProfileByNickname,
    getCurrentProfileEmailSubscriptionStatus,
    lookupProfileByLogin,
    getProfileForPasswordReset
};
