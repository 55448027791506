import ufeApi from 'services/api/ufeApi';
import Empty from 'constants/empty';

function getVariations({ variationIds, channel, language, country }) {
    const variations = variationIds.join(',');
    const queryParams = `?ch=${channel}&loc=${language}-${country}`;
    const path = `/api/content/bulk/p13nVariation/${variations}`;

    const url = `${path}${queryParams}`;

    return ufeApi.makeRequest(url, { method: 'GET' }).then(({ data = Empty.Array, errorCode }) => {
        if (errorCode) {
            return Promise.reject(data);
        }

        return data;
    });
}

export default getVariations;
