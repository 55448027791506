/* global insideFrontInterface */
/* eslint-disable class-methods-use-this */
import React from 'react';
import store from 'Store';
import urlUtils from 'utils/Url';
import locationUtils from 'utils/Location';
import FrameworkUtils from 'utils/framework';
import BaseClass from 'components/BaseClass/BaseClass';
import scriptUtil from 'utils/LoadScripts';
import processEvent from 'analytics/processEvent';
import analyticsConsts from 'analytics/constants';
import chatActions from 'actions/ChatActions';
import skuUtils from 'utils/Sku';
import basketUtils from 'utils/Basket';
import orderUtils from 'utils/Order';
import BCC from 'utils/BCC';
import Storage from 'utils/localStorage/Storage';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import UI from 'utils/UI';
import HomeChatUtils from 'utils/HomeChat';
import LanguageLocale from 'utils/LanguageLocale';
import agentAwareUtils from 'utils/AgentAware';
import { breakpoints } from 'style/config';
import TermsAndConditionsActions from 'actions/TermsAndConditionsActions';
import { HydrationFinished } from 'constants/events';
import { globalModals, renderModal } from 'utils/globalModals';

const { PRIVACY_POLICY } = globalModals;
const { IMAGE_SIZES, MEDIA_IDS } = BCC;
const { wrapComponent } = FrameworkUtils;
const { getLocaleResourceFile } = LanguageLocale;
const getText = getLocaleResourceFile('components/GlobalModals/SignInModal/SignInFormFooter/locales', 'SignInFormFooter');

class SmartChat extends BaseClass {
    state = {};

    componentDidMount() {
        this.isChatAvailable = null;
        this.isChatVisible = null;
        this.skuCategoriesMap = {};
        this.chatObserver = null;
        this.chatAvailableCallbackFired = false;

        //_inside gets called initially after chat loads, recommended way of binding functions to
        //powerfront events fired from smart chat code.
        window._inside = window._inside || [];
        window._inside.push(
            {
                action: 'bind',
                name: 'chatavailable',
                callback: this.onChatAvailable
            },
            {
                action: 'bind',
                name: 'onload',
                callback: this.onChatLoad
            },
            {
                action: 'bind',
                name: 'chat_visible',
                callback: this.onChatVisible
            },
            {
                action: 'bind',
                name: 'chatnotification',
                callback: this.onChatNotification
            },
            {
                action: 'bind',
                name: 'chatended',
                callback: this.onChatEnded
            }
        );

        let insideUrl;

        if (location.host.indexOf('dev') !== -1 || location.host.indexOf('sitetraining') !== -1) {
            insideUrl = 'us-sandbox2-track.inside-graph.com/gtm/IN-1000768/inside.min.js';
        } else {
            const envToInsideIdMap = {
                LOCAL: 'us-sandbox2-track.inside-graph.com/gtm/IN-1000540/inside.min.js',
                QA: 'us-sandbox2-track.inside-graph.com/gtm/IN-1000767/inside.min.js',
                PROD: 'sephora-track.inside-graph.com/gtm/IN-1000800/inside.min.js'
            };
            insideUrl = envToInsideIdMap[Sephora.UFE_ENV];
        }

        scriptUtil.loadScripts(['https://' + insideUrl], null, true);

        store.setAndWatch({ 'page.product': 'product' }, this, null, true);

        const onLastLoadEvent = Sephora.Util.onLastLoadEvent;

        onLastLoadEvent(window, [HydrationFinished], () => {
            window.addEventListener('OpenPrivacyModal', () => {
                this.showPrivacyPolicyModal();
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('OpenPrivacyModal', this.showPrivacyPolicyModal);
    }

    showPrivacyPolicyModal = () => {
        //close the chat pane for mobile devices
        if (window.matchMedia(breakpoints.smMax).matches) {
            HomeChatUtils.closeChatPane();
        }

        renderModal(this.props.globalModals[PRIVACY_POLICY], () => {
            store.dispatch(TermsAndConditionsActions.showModal(true, MEDIA_IDS.PRIVACY_POLICY_MODAL, getText('privacyPolicyLink')));
        });
    };

    updateContext = () => {
        //forces update of user info on powerfront's side
        if (window._insideContextUpdate && typeof window._insideContextUpdate === 'function') {
            window._insideContextUpdate();
        }
    };

    getProductDisplayName = () => {
        const product = this.state.product;
        const productDetails = product.productDetails || product;
        let displayName = productDetails.displayName;

        if (productDetails.brand && productDetails.brand.displayName) {
            displayName = `${productDetails.brand.displayName} ${displayName}`;
        }

        return displayName;
    };

    getProductImage = skuImages => {
        if (skuImages) {
            const imagePath = skuUtils.getImgSrc(IMAGE_SIZES[162], skuImages);
            const host = Sephora.UFE_ENV !== 'LOCAL' ? locationUtils.getLocation().origin : '//qa.sephora.com';

            return `${host}${imagePath}`;
        }

        return null;
    };

    getBrand = () => {
        const product = this.state.product;
        const productDetails = product.productDetails || product;
        const brand = productDetails.brand?.displayName || '';

        return brand;
    };

    removeCurrency = value => {
        return value ? Number(basketUtils.removeCurrency(value)) : 0;
    };

    onChatLoad = () => {
        window.powerfront = {
            pageName: digitalData.page.attributes.sephoraPageInfo.pageName,
            pageType: locationUtils.isOrderConfirmationPage() ? 'orderconfirmed' : digitalData.page.category.pageType
        };

        if (locationUtils.isProductPage() && typeof this.state.product === 'object') {
            const { product = {} } = this.state;
            const image = product.currentSku && product.currentSku.skuImages ? this.getProductImage(product.currentSku.skuImages) : null;
            window.powerfront = {
                ...window.powerfront,
                pageName: this.getProductDisplayName(),
                image,
                brand: this.getBrand()
            };
        }

        if (locationUtils.isBrandNthCategoryPage()) {
            window.powerfront = {
                ...window.powerfront,
                brand: digitalData.page.attributes.brand
            };
        }

        //close the chat pane always on every load
        HomeChatUtils.closeChatPane();

        store.setAndWatch('user', this, data => {
            const user = data.user;
            window.powerfront = {
                ...window.powerfront,
                email: user.login,
                emailHash: user.emailHashed,
                id: user.profileId
            };
            this.updateContext();
        });

        if (!locationUtils.isCheckout()) {
            store.setAndWatch('basket', this, data => {
                const { basket } = data;

                if (this.hydrateCartObject(basket)) {
                    this.updateContext();
                }
            });
        } else {
            store.setAndWatch('order.orderDetails', this, orderData => {
                const { orderDetails = {} } = orderData;

                if (!orderDetails.isInitialized) {
                    return;
                }

                if (this.hydrateOrderObject(orderDetails)) {
                    this.updateContext();
                }
            });
        }
    };

    hydrateCartObject = (basket = {}) => {
        const subtotal = this.removeCurrency(basket.subtotal);

        if (this.isBasketChanged(subtotal, basket)) {
            window.powerfront.cart = {
                total: subtotal,
                discountAmount: -this.removeCurrency(basket.discountAmount),
                itemCount: basket.itemCount,
                currency: basket.currency,
                items: this.mapBasketItems(basket.items)
            };

            return true;
        }

        return false;
    };

    hydrateOrderObject = (orderDetails = {}) => {
        const pfOrder = window.powerfront.order;
        const { items = {}, priceInfo = {} } = orderDetails;

        const removeCurrency = this.removeCurrency;

        const orderAmount = removeCurrency(orderUtils.getOrderTotal(orderDetails));
        const giftCardRedeemedAmount = removeCurrency(priceInfo.giftCardAmount) + removeCurrency(priceInfo.eGiftCardAmount);
        const giftCardAmountChanged =
            pfOrder &&
            ((typeof pfOrder.giftCardRedeemedAmount === 'undefined' && giftCardRedeemedAmount > 0) ||
                (typeof pfOrder.giftCardRedeemedAmount !== 'undefined' && pfOrder.giftCardRedeemedAmount < giftCardRedeemedAmount));

        if (!pfOrder || pfOrder.total !== orderAmount || pfOrder.itemCount !== items.itemCount || giftCardAmountChanged) {
            window.powerfront.order = {
                total: orderAmount,
                merchandiseSubtotal: removeCurrency(priceInfo.merchandiseSubtotal) + removeCurrency(priceInfo.giftCardSubtotal),
                discountAmount: -removeCurrency(priceInfo.promotionDiscount),
                taxAmount: removeCurrency(priceInfo.tax),
                shippingAmount: removeCurrency(priceInfo.totalShipping),
                itemCount: items.itemCount,
                currency: items.currency,
                items: this.mapBasketItems(items.items)
            };

            if (priceInfo.giftCardAmount || priceInfo.eGiftCardAmount) {
                window.powerfront.order.giftCardRedeemedAmount = -(
                    removeCurrency(priceInfo.giftCardAmount) + removeCurrency(priceInfo.eGiftCardAmount)
                );
            }

            if (priceInfo.storeCardAmount) {
                window.powerfront.order.storeCreditsAmount = -removeCurrency(priceInfo.storeCardAmount);
            }

            if (locationUtils.isOrderConfirmationPage()) {
                window.powerfront.order.id = orderUtils.getOrderId();
            }

            return true;
        }

        return false;
    };

    getBasketItemCategory = (sku = {}) => {
        if (!sku.skuId) {
            return '';
        }

        const buildCategoryPath = (catItem = {}, catList = []) => {
            const { displayName, parentCategory, categoryId } = catItem;
            displayName && categoryId !== skuUtils.HIDDEN_CATEGORY_ID && catList.push(displayName);

            return parentCategory ? buildCategoryPath(parentCategory, catList) : catList.reverse();
        };

        if (sku.parentCategory) {
            this.skuCategoriesMap[sku.skuId] = buildCategoryPath(sku.parentCategory).join(' > ');
        }

        return this.skuCategoriesMap[sku.skuId] || '';
    };

    mapBasketItems = (items = []) => {
        return (
            (items &&
                items.length &&
                items.map(item => {
                    const { sku } = item;
                    const isPriceInPoints = skuUtils.isBiReward(sku);
                    let productName = sku.productName || '';

                    if (sku.brandName) {
                        productName = `${sku.brandName} ${productName}`;
                    }

                    const targetUrl = sku.fullSizeSku && sku.fullSizeSku.targetUrl ? sku.fullSizeSku.targetUrl : sku.targetUrl;

                    const mappedItem = {
                        name: productName,
                        sku: sku.skuId,
                        price: !isPriceInPoints ? this.removeCurrency(sku.salePrice || sku.listPrice) : 0,
                        qty: item.qty,
                        itemurl: targetUrl ? locationUtils.getLocation().origin + urlUtils.getLink(targetUrl) : '',
                        img: this.getProductImage(sku.skuImages),
                        category: this.getBasketItemCategory(sku)
                    };

                    if (sku.variationTypeDisplayName && sku.variationValue) {
                        mappedItem.variation = {
                            name: sku.variationTypeDisplayName,
                            value: sku.variationValue
                        };
                    }

                    return mappedItem;
                })) ||
            []
        );
    };

    isBasketChanged = (subtotal, basket = {}) => {
        const { cart = {} } = window.powerfront;

        return !(cart.total === subtotal && cart.itemCount === basket.itemCount);
    };

    onChatAvailable = isAvailable => {
        if (isAvailable && !this.isChatAvailable) {
            const openchatParam = urlUtils.getParamsByName('openchat');

            //deeplink from apps to mobile contact us page forces open
            //smartchat if smartchat is available.
            if (Sephora.isMobile() && locationUtils.isContactUsPage() && openchatParam && openchatParam[0] === 'true') {
                insideFrontInterface.openChatPane();
            }
        }

        if (this.isChatAvailable !== isAvailable) {
            this.isChatAvailable = !!isAvailable;
            this.dispatchUpdateEvent();
        }

        if (!this.chatAvailableCallbackFired) {
            this.initOpenChatObserver();
            this.chatAvailableCallbackFired = true;
        }
    };

    onChatVisible = isVisible => {
        if (this.isChatVisible !== isVisible) {
            this.isChatVisible = !!isVisible;
            this.dispatchUpdateEvent();
        }
    };

    dispatchUpdateEvent = (chatData = {}, isChatOpen = false) => {
        store.dispatch(chatActions.updateSmartChatState(this.isChatAvailable, this.isChatVisible, chatData, isChatOpen));
    };

    onChatNotification = data => {
        Storage.session.setItem(LOCAL_STORAGE.CUSTOMER_CHAT_IS_PROACTIVE_CHAT, true);
        this.dispatchUpdateEvent(data);
        processEvent.process(analyticsConsts.LINK_TRACKING_EVENT, {
            data: {
                linkName: 'D=c55',
                actionInfo: `proactive ${analyticsConsts.LinkData.CUSTOMER_CHAT_DISPLAYED}`
            }
        });
    };

    trackChatOpenAnalytics = () => {
        processEvent.process(analyticsConsts.ASYNC_PAGE_LOAD, {
            data: {
                pageName: `${analyticsConsts.PAGE_NAMES.SMART_CHAT}:n/a:*`,
                pageDetail: 'home chat',
                pageType: 'chat'
            }
        });
    };

    onChatOpen = () => {
        this.dispatchUpdateEvent(null, true);
        this.trackChatOpenAnalytics();
    };

    onChatOpenFirstTime = () => {
        const isChatSessionStarted = Storage.session.getItem(LOCAL_STORAGE.CUSTOMER_CHAT_SESSION_STARTED);
        const isProactiveChat = Storage.session.getItem(LOCAL_STORAGE.CUSTOMER_CHAT_IS_PROACTIVE_CHAT);

        if (!isChatSessionStarted) {
            // For analytics purposes a chat session is being considered as started if the user opens the chat window for the first time
            Storage.session.setItem(LOCAL_STORAGE.CUSTOMER_CHAT_SESSION_STARTED, true);

            processEvent.process(analyticsConsts.LINK_TRACKING_EVENT, {
                data: {
                    linkName: 'D=c55',
                    actionInfo: `${isProactiveChat ? 'proactive' : 'reactive'} ${analyticsConsts.LinkData.CUSTOMER_CHAT_SESSION_STARTED}`,
                    eventStrings: [analyticsConsts.Event.EVENT_220]
                }
            });

            // This is a separate call from the one above, it will be fired everytime the window chat is opened
            this.trackChatOpenAnalytics();
        }
    };

    onChatEnded = () => {
        const isProactiveChat = Storage.session.getItem(LOCAL_STORAGE.CUSTOMER_CHAT_IS_PROACTIVE_CHAT);
        processEvent.process(analyticsConsts.LINK_TRACKING_EVENT, {
            data: {
                linkName: 'D=c55',
                actionInfo: `${isProactiveChat ? 'proactive' : 'reactive'} ${analyticsConsts.LinkData.CUSTOMER_CHAT_SESSION_ENDED}`,
                eventStrings: [analyticsConsts.Event.EVENT_221]
            }
        });

        const isChatSessionStarted = Storage.session.getItem(LOCAL_STORAGE.CUSTOMER_CHAT_SESSION_STARTED);

        if (isChatSessionStarted) {
            Storage.session.setItem(LOCAL_STORAGE.CUSTOMER_CHAT_SESSION_STARTED, false);
        }

        if (isProactiveChat) {
            Storage.session.setItem(LOCAL_STORAGE.CUSTOMER_CHAT_IS_PROACTIVE_CHAT, false);
        }
    };

    initOpenChatObserver = () => {
        const chatWrapper = document.getElementById('inside_holder');

        if (Sephora.isAgent) {
            chatWrapper.classList.add(agentAwareUtils.applyHideAgentAwareClass());
        }

        let prevState = chatWrapper?.classList?.contains('chatPaneOpen');

        if (chatWrapper) {
            this.chatObserver = UI.observeElement(mutations => {
                mutations.forEach(mutation => {
                    if (mutation?.attributeName === 'class') {
                        const hasOpenClass = mutation?.target.classList.contains('chatPaneOpen');

                        if (prevState !== hasOpenClass) {
                            const isChatSessionStarted = Storage.session.getItem(LOCAL_STORAGE.CUSTOMER_CHAT_SESSION_STARTED);
                            const isChatPaneAlreadyOpen = Storage.session.getItem(LOCAL_STORAGE.CUSTOMER_CHAT_PANE_OPEN);

                            if (hasOpenClass && !isChatPaneAlreadyOpen) {
                                this.onChatOpenFirstTime();
                                Storage.session.setItem(LOCAL_STORAGE.CUSTOMER_CHAT_PANE_OPEN, true);
                            }

                            if (hasOpenClass && isChatPaneAlreadyOpen) {
                                // This is executed after 1st time
                                this.onChatOpen();
                            }

                            if (!isChatSessionStarted && !hasOpenClass && isChatPaneAlreadyOpen) {
                                Storage.session.setItem(LOCAL_STORAGE.CUSTOMER_CHAT_PANE_OPEN, false);
                            }

                            prevState = hasOpenClass;
                        }
                    }
                });
            });

            if (this.chatObserver) {
                this.chatObserver.observe(chatWrapper, {
                    attributes: true,
                    attributeFilter: ['class']
                });
            }
        }
    };

    render() {
        return <div></div>;
    }
}

export default wrapComponent(SmartChat, 'SmartChat');
