const ERROR = {
    ERR_CAMP_REF_CMP_NOT_STARTED: 'ERR_CAMP_REF_CMP_NOT_STARTED',
    ERR_CAMP_REF_CMP_EXPIRED: 'ERR_CAMP_REF_CMP_EXPIRED',
    ERR_CAMP_REF_MAX_COUNT_RCHD: 'ERR_CAMP_REF_MAX_COUNT_RCHD',
    ERR_CAMP_REF_INVALID: 'ERR_CAMP_REF_INVALID',
    ERR_CAMP_REF_INVALID_COUNTRY: 'ERR_CAMP_REF_INVALID_COUNTRY',
    ERR_CAMP_REFEREE_ALREADY_REGISTERED: 'ERR_CAMP_REFEREE_ALREADY_REGISTERED',
    ERR_CAMP_REFEREE_MAX_COUNT_RCHD: 'ERR_CAMP_REFEREE_MAX_COUNT_RCHD',
    ERR_CAMP_REFERRER_SELF_REGISTRATION_NOT_ALLOWED: 'ERR_CAMP_REFERRER_SELF_REGISTRATION_NOT_ALLOWED',
    CUSTOM_ALREADY_BI: 'CUSTOM_ALREADY_BI',
    CUSTOM_ADVOCACY_DOWN: 'CUSTOM_ADVOCACY_DOWN',
    CUSTOM_BI_DOWN: 'CUSTOM_BI_DOWN',
    ERR_CE_CMP_NOT_FOUND: 'ERR_CE_CMP_NOT_FOUND'
};

const CAMPAIGN_TYPE = {
    BISIGNUP: 'BISIGNUP',
    FNF: 'FnF'
};

const SHARE = 'share';

const ACTION_TYPE = {
    SHOW_ERROR: 'SHOW_ERROR',
    SHOW_SUCCESS_PAGE: 'SHOW_SUCCESS_PAGE',
    SET_CAMPAIGN_DATA: 'SET_CAMPAIGN_DATA',
    SHOW_LANDING_PAGE: 'SHOW_LANDING_PAGE',
    RESET: 'RESET'
};

const PAGE_TYPES = {
    LANDING: 'landing',
    SUCCESS: 'success',
    ERROR_PAGE: 'error'
};

const X_REFERRAL_TTL_TOKEN = 'x-referral-ttl-token';

export {
    ERROR, CAMPAIGN_TYPE, SHARE, ACTION_TYPE, X_REFERRAL_TTL_TOKEN, PAGE_TYPES
};
