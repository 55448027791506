export default function getResource(label, vars = []) {
    const resources = {
        rsvpTitle: 'Vous avez confirmé votre présence!',
        confirmationWithPhoneMsg: 'Vous recevrez un texto de confirmation au ',
        confirmationWithEmailMsg: 'Vous recevrez un courriel de confirmation au ',
        confirmationNum: 'Numéro de confirmation',
        addToCal: 'Ajouter au calendrier',
        cancelRsvp: 'Annuler la réponse',
        cancel: 'Annuler',
        reschedule: 'Changer la date',
        viewAll: 'Voir toutes les réservations',
        areYouSure: 'Souhaitez-vous vraiment annuler la confirmation de votre présence?',
        no: 'Non',
        yes: 'Oui',
        youAreBookedTitle: 'Vous avez réservé!',
        emailConfirmation: 'Vous recevrez un courriel de confirmation au',
        artist: 'Artiste',
        confirmationNumber: 'Numéro de confirmation',
        addToCalendarButton: 'Ajouter au calendrier',
        rescheduleButton: 'Changer la date',
        cancelButton: 'Annuler',
        viewAllReservations: 'Voir toutes les réservations',
        areYouSureCancel: 'Souhaitez-vous vraiment annuler?',
        areYouSureWithin24Hours: `Souhaitez-vous vraiment annuler? Une annulation dans les 24 heures précédant votre rendez-vous entraînera des frais de ${vars[0]}.`,
        seePolicies: 'Consulter les politiques',
        moreDetails: 'pour plus de détails.',
        cancelService: 'Annuler le service',
        areYouSureRescheduleWithin24Hours: `Souhaitez-vous vraiment replanifier votre rendez-vous? Replanifier un rendez-vous dans les 24 heures qui le précèdent entraînera des frais de ${vars[0]}.`,
        rescheduleService: 'Replanifier le service',
        areYouSureReschedule: 'Souhaitez-vous vraiment replanifier votre rendez-vous?',
        yesReschedule: 'Oui, replanifier',
        bookAgain: 'Réserver à nouveau',
        productRecs: 'Afficher les recommandations',
        rsvp: 'Réserver',
        cancelWaitlist: 'Annuler la liste d’attente',
        bookNow: 'Réserver',
        findAnotherTime: 'Trouvez une autre plage horaire',
        declineAndCancel: 'Refuser et annuler',
        declineAndCancelWaitlist: 'Refuser et annuler la liste d’attente',
        declineCancelWaitlist: 'Refuser et annuler la liste d’attente',
        areYouSureDeclineAndCancel: 'Souhaitez-vous vraiment refuser et annuler votre place sur la liste d’attente? En annulant, vous renoncerez à votre rendez-vous exclusif.',
        areYouSureCancelWaitlist: 'Souhaitez-vous vraiment annuler votre place sur la liste d’attente? En annulant, vous renoncerez à votre statut prioritaire pour tous les rendez-vous à venir qui correspondent à vos disponibilités.'
    };

    return resources[label];
}
