import { UPDATE_PROFILE_STATUS } from 'constants/actionTypes/auth';

const updateProfileStatus = profileStatus => dispatch => {
    dispatch({
        type: UPDATE_PROFILE_STATUS,
        payload: profileStatus
    });
};

export default { updateProfileStatus };
