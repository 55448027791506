export default function getResource(label) {
    const resources = {
        qty: 'Quantité',
        inBasket: 'dans le panier',
        standardShipping: 'Faites livrer',
        sdd: 'for Same-Day Delivery',
        bopis: 'for Store Pickup'
    };
    return resources[label];
}
