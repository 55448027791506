export default function getResource(label, vars = []) {
    const resources = {
        rsvpTitle: 'You’re RSVP’d!',
        confirmationWithPhoneMsg: 'You\'ll receive a confirmation text at ',
        confirmationWithEmailMsg: 'You\'ll receive a confirmation email at ',
        confirmationNum: 'Confirmation Number',
        addToCal: 'Add to Calendar',
        cancelRsvp: 'Cancel RSVP',
        cancel: 'Cancel',
        reschedule: 'Reschedule',
        viewAll: 'View All Reservations',
        areYouSure: 'Are you sure you want to cancel your RSVP?',
        no: 'No',
        yes: 'Yes',
        youAreBookedTitle: 'You\'re booked!',
        emailConfirmation: 'You\'ll receive a confirmation email at',
        artist: 'Artist',
        confirmationNumber: 'Confirmation Number',
        addToCalendarButton: 'Add To Calendar',
        rescheduleButton: 'Reschedule',
        cancelButton: 'Cancel',
        viewAllReservations: 'View All Reservations',
        areYouSureCancel: 'Are you sure you want to cancel?',
        areYouSureWithin24Hours: `Are you sure you want to cancel? Canceling within 24 hours of your appt will incur a ${vars[0]} charge.`,
        seePolicies: 'See policies',
        moreDetails: 'for more details.',
        cancelService: 'Cancel Service',
        areYouSureRescheduleWithin24Hours: `Are you sure you want to reschedule? Rescheduling within 24 hours of your appt will incur a ${vars[0]} charge.`,
        rescheduleService: 'Reschedule Service',
        areYouSureReschedule: 'Are you sure you want to reschedule?',
        yesReschedule: 'Yes, Reschedule',
        bookAgain: 'Book Again',
        productRecs: 'View Product Recs',
        rsvp: 'RSVP',
        cancelWaitlist: 'Cancel Waitlist',
        bookNow: 'Book Now',
        findAnotherTime: 'Find Another Time',
        declineAndCancel: 'Decline & Cancel',
        declineAndCancelWaitlist: 'Decline and Cancel Waitlist',
        declineCancelWaitlist: 'Decline & Cancel Waitlist',
        areYouSureDeclineAndCancel: 'Are you sure you want to decline and cancel your waitlist exclusive hold? By canceling, you will relinquish your exclusive hold on the appointment.',
        areYouSureCancelWaitlist: 'Are you sure you want to cancel your waitlist reservation? By canceling, you will relinquish your priority status for any upcoming appointments that match your preferred date and time range.'
    };

    return resources[label];
}
