const ShoppingListUtils = {
    isSLSServiceEnabled: isSLSABTestEnabled => {
        if (Sephora.configurationSettings.isSLSServiceEnabled && isSLSABTestEnabled) {
            return true;
        }

        return false;
    }
};

export default ShoppingListUtils;
