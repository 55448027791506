/* global insideFrontInterface */

import PageTemplateType from 'constants/PageTemplateType';
import Location from 'utils/Location';

const closeChatPane = () => {
    if (
        typeof insideFrontInterface !== 'undefined' &&
        insideFrontInterface.chat &&
        insideFrontInterface.chat.closeChatPane &&
        typeof insideFrontInterface.chat.closeChatPane === 'function'
    ) {
        insideFrontInterface.chat.closeChatPane();
    }
};

//Should not include SmartChat powerfront script on
//Checkout, Edit Play Subscription, or My Account pages
const isSmartChatSecuredPage = template => {
    switch (template) {
        case PageTemplateType.Checkout:
        case PageTemplateType.EditPlaySubscription:
        case PageTemplateType.Addresses:
        case PageTemplateType.EmailPostal:
        case PageTemplateType.OrderDetails:
        case PageTemplateType.Orders:
        case PageTemplateType.PaymentMethods:
        case PageTemplateType.ResetPassword:
        case PageTemplateType.Subscriptions:
        case PageTemplateType.MyAccount:
        case PageTemplateType.VendorGenericLogin:
            return true;
        default:
            return false;
    }
};

const shouldShowSmartChat = template => {
    const result =
        Sephora.configurationSettings.enableSmartChat &&
        (Sephora.configurationSettings.enableSmartChatOnSecuredPages || !isSmartChatSecuredPage(template)) &&
        !Location.isPreviewSettings();

    return result;
};

export default {
    closeChatPane,
    shouldShowSmartChat
};
