const resources = {
    greeting: 'Hi',
    signIn: 'Sign In',
    signInPrompt: 'for FREE Shipping',
    biHeading: 'Beauty Insider Summary',
    biDesc: 'View activity, savings, benefits',
    joinNow: 'Join Now',
    rewardsHeading: 'Rewards Bazaar',
    rewardsDesc: 'Redeem items, samples, more',
    pointsLabel: 'Point',
    chooseBirthdayGift: 'Choose your Birthday gift!',
    ccHeading: 'Sephora Credit Card Program',
    ccDesc: 'View and manage credit card and rewards',
    ccRewardsLabel: 'Rewards',
    ccApplyNow: 'Apply now',
    ordersHeading: 'Orders',
    ordersDesc: 'View & track online or pickup orders',
    autoReplenishHeading: 'Auto-Replenish',
    autoReplenishDescWithSubs: 'View and manage your subscriptions',
    autoReplenishDesc: 'Never run out of your go-tos with subscription delivery',
    purchasesHeading: 'Buy It Again',
    purchasesDesc: 'Reorder from in-store and online purchases',
    lovesHeading: 'Loves',
    lovesDesc: 'View saved products',
    recHeading: 'Beauty Advisor Recommendations',
    recDesc: 'Recommendations from your store visits',
    reservationsHeading: 'Reservations',
    reservationsDesc: 'Manage your services and events',
    beautyPrefHeading: 'Beauty Preferences',
    beautyPrefDesc: 'Complete to see your personalized recommendations',
    guidedSellingBeautyPrefDesc: 'Complete for personalized recommendations and filters',
    accountHeading: 'Account Settings',
    accountDesc: 'Payment, contact info, addresses, password',
    signOut: 'Sign Out',
    sameDayUnlimitedHeading: 'Same-Day Unlimited',
    sameDayUnlimitedMemberDesc: 'Manage your subscription',
    sameDayUnlimitedNonMemberDesc: 'Get Unlimited Free Same-Day Delivery',
    subscribeToday: 'Subscribe today',
    beautyChallenges: 'Beauty Insider Challenges',
    new: 'NEW',
    beautyChallengesDescription: 'Earn points when you complete tasks'
};

export default function getResource(label) {
    return resources[label];
}
