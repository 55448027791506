import { UPDATE_PROFILE_STATUS } from 'constants/actionTypes/auth';

const initialState = {
    profileStatus: 0
};

const reducer = function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_PROFILE_STATUS:
            return {
                ...state,
                profileStatus: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
