import localeUtils from 'utils/LanguageLocale';

const getText = localeUtils.getLocaleResourceFile('constants/locales', 'Basket');

// fulfillmenttype
const BasketType = {
    Standard: null,
    SameDay: 'sameday',
    BOPIS: 'ROPIS' || 'buy online and pick up basket'
};

const API_BASKET_TYPE = {
    sameday: 'SameDay',
    standard: 'ShipToHome',
    ROPIS: 'Pickup',
    BOPIS: 'Pickup'
};

export default {
    MESSAGES: {
        BASKET_LEVEL: {
            FREE_SHIPPING: getText('freeShipping')
        }
    },
    DELIVERY_OPTIONS: {
        STANDARD: 'Standard',
        ROUGE_FREE_SHIPPING: 'Rouge Free Shipping',
        SAME_DAY: 'Sameday',
        PICKUP: 'Pickup',
        AUTO_REPLENISH: 'Auto-Replenish'
    },
    CONTEXT: { RW_REMAINING_BALANCE: 'basket.rwRemainingBalance' },
    // The minimum points at which a reward is valuable enough to be shipping without a product
    // also being in the order
    STANDALONE_REWARD_MIN_VAL: 750,
    POINTS_FOR_DISCOUNT_MIN_VAL: 500,
    // Warning messages (key/messageContext) we don't want to show in the Inline Basket and show them
    // in a different way in Basket page list item
    WARNING_BLACKLIST_MESSAGES: [],
    SHOPPING_LIST_PROMO_WARNING: 'shoppingList.promoWarning',
    PROMO_WARNING: 'basket.promoWarning',
    RESTRICTED_ITEMS_REMOVED: 'basket.restrictedItemsRemoved',
    PICK_UP_ITEMS_OUT_OF_STOCK_SKU: 'basket.pickupsku.outOfStock',
    AMOUNT_TO_FREE_SHIPPING: 'basket.amountToFreeShipping',
    FREE_SHIPPING_THRESHOLD: 'basket.thresholdFreeShipping',
    FREE_SHIPPING_ROUGE: 'basket.rougeFreeShipping',
    FREE_SHIPPING_ROUGE_CA: 'basket.rougeCAFreeShipping',
    FREE_SHIPPING_ROUGE_HAZMAT: 'basket.rougeHazmatFreeShipping',
    CBR_PROMO_MESSAGE: 'basket.promoMessages.cbr',
    PFD_PROMO_MESSAGE: 'basket.promoMessages.pfd',
    SED_CLOSE_PROMO_MESSAGE: 'basket.promotion.sed.close',
    BasketType,
    API_BASKET_TYPE,
    ERROR_CODES: {
        ERROR_CODE_1093: -1093
    },
    ERROR_KEYS: {
        LIMIT_EXCEEDED: 'basket.lineItem.purchaseLimit.exceeded',
        OUT_OF_STOCK: 'basket.sku.outOfStock'
    },
    GIFT_MESSAGE_STATUS: {
        NOT_AVAILABLE: 0,
        AVAILABLE: 1,
        ADDED: 2
    },
    LANGUAGE_OPTIONS: {
        FRENCH: 'giftMessages_fr-CA'
    },
    ALL_TITLE: {
        ENGLISH: 'All',
        FRENCH: 'Tout'
    }
};
