/* eslint-disable class-methods-use-this */
import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';

import {
    Text, Flex, Image, Box, Divider
} from 'components/ui';
import { colors } from 'style/config';
import HappeningImg from 'components/SharedComponents/HappeningImg';

import { formatAppointmentTimeFrame, formatAppointmentDate } from 'utils/happening';
import localeUtils from 'utils/LanguageLocale';
import { getStatusLabelAndIcon } from 'utils/happeningReservation';
import { EDP_IMG_SIZES } from 'components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/constants';

const { getLocaleResourceFile } = localeUtils;

const { SMUI_SINGLE_HEIGHT, LGUI_SINGLE_HEIGHT, SMUI_EVENTS_SINGLE_HEIGHT, LGUI_EVENTS_SINGLE_HEIGHT } = EDP_IMG_SIZES;

function DetailsWrapper({ children, details = {} }) {
    const getText = getLocaleResourceFile('components/Content/Happening/ReservationDetails/DetailsWrapper/locales', 'DetailsWrapper');
    const {
        status,
        type,
        subStatus,
        serviceFees,
        startDateTime,
        duration,
        imageUrl,
        store,
        description,
        experienceType = 'event',
        appointmentTime = null
    } = details;

    const { icon, statusLabel } = getStatusLabelAndIcon({
        status,
        type,
        subStatus,
        serviceFees,
        startDateTime,
        store,
        duration
    });

    const imageHeightLGUI = type === 'events' ? LGUI_EVENTS_SINGLE_HEIGHT : LGUI_SINGLE_HEIGHT;
    const imageHeightSMUI = type === 'events' ? SMUI_EVENTS_SINGLE_HEIGHT : SMUI_SINGLE_HEIGHT;

    return (
        <Flex
            paddingTop={[null, null, 4]}
            flexDirection={'column'}
            gap={[5, null, 6]}
        >
            <Flex
                gap={[4, null, 7]}
                flexDirection={['column', null, 'row']}
                alignItems={[null, null, 'flex-start']}
            >
                <HappeningImg
                    width={['100vw', null, '100%']}
                    height={[imageHeightSMUI, null, imageHeightLGUI]}
                    maxWidth={[null, null, 624]}
                    marginX={[-4, null, 0]}
                    css={{ flex: 1, objectFit: 'cover' }}
                    src={imageUrl}
                />
                <Box width={[null, null, 565]}>
                    <Flex
                        alignItems={'center'}
                        gap={1}
                    >
                        <Image
                            height={12}
                            width={12}
                            src={`/img/ufe/${icon}`}
                        />
                        <Text
                            is={'p'}
                            fontSize={'sm'}
                            color={'gray'}
                            children={statusLabel}
                        />
                    </Flex>
                    <Text
                        is={'h1'}
                        fontSize={'xl'}
                        fontWeight={'bold'}
                        marginBottom={4}
                        children={getText('reservationDetails')}
                    />
                    <Flex
                        gap={4}
                        marginBottom={3}
                    >
                        <Box width={105}>
                            <Text
                                fontWeight={'bold'}
                                lineHeight={'18px'}
                                children={getText('dateAndTime')}
                            />
                        </Box>
                        <Box width={[215, null, null]}>
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={formatAppointmentDate(startDateTime, store.timeZone)}
                            />
                            <Text
                                is={'p'}
                                lineHeight={'18px'}
                                children={appointmentTime || formatAppointmentTimeFrame(startDateTime, store.timeZone, duration)}
                            />
                        </Box>
                    </Flex>
                    {children}
                </Box>
            </Flex>
            <Divider color={colors.nearWhite} />
            <Box maxWidth={824}>
                <Text
                    is={'h2'}
                    fontWeight={'bold'}
                    fontSize={['md', null, 'lg']}
                    lineHeight={['20px', null, '22px']}
                    marginBottom={2}
                    children={getText(`${experienceType}Description`)}
                />
                <Text
                    is={'p'}
                    lineHeight={[null, null, '18px']}
                    children={description}
                />
            </Box>
        </Flex>
    );
}

export default wrapFunctionalComponent(DetailsWrapper, 'DetailsWrapper');
